import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import Selectors from '../core/redux/selectors';
import * as searchActions from '../patientSearch/redux/actions';
import { LoadingMessage } from '../../core/index';
import * as actions from './redux/actions';
import GeneticDataForm from './forms/GeneticDataForm/GeneticDataForm';

import type { Genetics, PatientState } from '../types';
import type { RouterMatch } from '../../core/types';
import type { ReduxState } from '../../rootReducer';

type OwnProps = {
  match: RouterMatch;
};
type StateProps = {
  isPatientBeingSaved: boolean;
  patient?: PatientState;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  isPatientBeingSaved: Selectors.isPatientBeingSaved(state),

  patient: Selectors.getPatient(state, ownProps.match.params.patientId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getPatientDetails: bindActionCreators(searchActions.getPatientDetails, dispatch),
  savePatientGenetics: bindActionCreators(actions.savePatientGenetics, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class PatientGeneticUpdate extends PureComponent<Props> {
  static defaultProps = {
    patient: undefined,
  };

  componentDidMount() {
    const { getPatientDetails, match } = this.props;
    const { patientId } = match.params;

    if (patientId) {
      getPatientDetails(patientId);
    }
  }

  render() {
    const { isPatientBeingSaved, patient } = this.props;
    return (
      <div className="col span_10_of_12 push_1">
        <h1 className="border-bottom-solid">Update Patient Genetic Data</h1>
        {isPatientBeingSaved || !patient ? (
          <LoadingMessage />
        ) : (
          <GeneticDataForm
            patientInfo={patient.patientInfo}
            showUpdatePatientDetailsButton
            saveGeneticDetails={this.saveGeneticDetails}
            submitBtnLabel="Save Patient Genetics"
          />
        )}
      </div>
    );
  }

  saveGeneticDetails = (geneticDetails: { [loci: string]: Genetics }, patientId: string) => {
    const { savePatientGenetics } = this.props;
    savePatientGenetics(geneticDetails, patientId);
  };
}

export default connector(PatientGeneticUpdate);
