import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import PatientSearchResultCard from './PatientSearchResultCard';
import Selectors from '../../core/redux/selectors';
import { ErrorMessage, LoadingMessage } from '../../../core';

import type { PatientState } from '../../types';
import type { ErrorMessages } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
  patientState?: PatientState;
  searchedPatientId: string;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  errorMessage: Selectors.getErrorMessage(state),
  hasErrored: Selectors.hasFetchPatientErrored(state),
  isFetching: Selectors.isFetchingPatient(state),

  patientState: Selectors.getPatient(state, Selectors.getSearchedPatientId(state)),
  searchedPatientId: Selectors.getSearchedPatientId(state) as string,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class PatientSearchResultCardLoadingContainer extends PureComponent<Props> {
  static defaultProps = {
    patientState: undefined,
  };

  render() {
    return this.renderSearchPatient();
  }

  renderSearchPatient = () => {
    const { errorMessage, hasErrored, isFetching, patientState, searchedPatientId } = this.props;
    if (searchedPatientId === '') {
      return null;
    }

    if (hasErrored) {
      return (
        <div style={{ marginTop: '10px' }}>
          <ErrorMessage errorMessages={errorMessage} />
        </div>
      );
    }

    return (
      <LoadingMessage isLoading={isFetching}>
        {patientState ? <PatientSearchResultCard patient={patientState.patientInfo} /> : null}
      </LoadingMessage>
    );
  };
}

export default connector(PatientSearchResultCardLoadingContainer);
