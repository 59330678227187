import React, { useEffect, useState } from 'react';
import MdChevronRight from 'react-icons/lib/md/chevron-right';
import ExpandMore from 'react-icons/lib/md/expand-more';
import { Link } from 'react-router-dom';
import { LoadingMessage } from '../../../core';
import { getDonorStatusName } from '../../../core/constants/donorStatuses';
import { getExternalInvestigationStatusName } from '../../../core/constants/externalInvestigationStatuses';
import { getExternalInvestigationTypeNameInTable } from '../../../core/constants/externalInvestigationTypes';
import formatGrid from '../../../core/helpers/gridFormatter';
import { formatDate } from '../../../core/helpers/dateHelpers';
import { formatInvestigationDetails } from '../../helpers/investigationDetailsFormatter';
import { orderExternalInvestigationActions } from '../../helpers/orderTestResults';
import { ExternalInvestigationActions } from '../../types';
import TestRequestActionsTable from './TestRequestActionsTable';
import TransplantCentreFilterToolBar from '../Common/TransplantCentreFilterToolBar';
import './test-requests.scss';
import { TransplantCentreTabType } from '../../constants/TransplantCentreTabTypes';

type Props = {
  isLoading: boolean;
  testRequests: ExternalInvestigationActions[];
};

export const TestRequestsTable = ({ testRequests, isLoading }: Props) => {
  const tableData = orderExternalInvestigationActions(testRequests);

  const [tableDataRows, setTableDataRows] = useState<(ExternalInvestigationActions | null)[]>(tableData);
  const [filteredTestRequestsTableData, setFilteredTestRequestsTableData] =
    useState<(ExternalInvestigationActions | null)[]>(tableData);
  useEffect(() => {
    setTableDataRows(tableData);
    setFilteredTestRequestsTableData(tableData);
  }, [testRequests]);

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const handleRowClick = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);
    setExpandedRows(newExpandedRows);
  };

  const isExpanded = (testRequest: ExternalInvestigationActions) => {
    const idx = expandedRows.find((id) => id === testRequest.id);

    return (idx as number) > -1;
  };

  const renderItem = (testRequest: ExternalInvestigationActions | null): JSX.Element[] => {
    const itemRows = [];
    if (testRequest) {
      const {
        actions,
        details,
        emdisRegistryName,
        grid,
        id,
        patientId,
        formattedPatientId,
        receivedDate,
        requestType,
        requestStatus,
        sampleArrival,
        donorStatus,
        temporaryUnavailableDate,
        transplantCentreName,
        reservedForThisPatient,
      } = testRequest;
      if (testRequest) {
        itemRows.push(
          <tr className={`centered-table-data${isExpanded(testRequest) ? ' expanded' : ''}`} key={`testRequest${id}`}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <td className="chevron" onClick={() => handleRowClick(id)} style={{ cursor: 'pointer' }}>
              {isExpanded(testRequest) ? <ExpandMore size="35" /> : <MdChevronRight size="35" />}
              {transplantCentreName}
            </td>
            <td>{formattedPatientId}</td>
            <td>{getExternalInvestigationTypeNameInTable(requestType)}</td>
            <td>{formatDate(receivedDate)}</td>
            <td>{formatInvestigationDetails(details, requestType).cell}</td>
            <td>{emdisRegistryName}</td>
            <td>{formatGrid(grid)}</td>
            <td>{getExternalInvestigationStatusName(requestStatus)}</td>
            <td>{formatDate(sampleArrival)}</td>
            <td>{getDonorStatusName(donorStatus, reservedForThisPatient)}</td>
            <td>{formatDate(temporaryUnavailableDate)}</td>
            <td>
              <Link target="_blank" to={`../patient/${patientId}/testRequests/${id}`}>
                View
              </Link>
            </td>
          </tr>
        );
        if (expandedRows.includes(id)) {
          itemRows.push(
            <tr key={`actionsTable${id}`}>
              <td colSpan={12} className="expanded-row">
                <TestRequestActionsTable
                  externalInvestigationActions={actions}
                  setTableDataRows={setTableDataRows}
                  externalInvestigationId={testRequest.id}
                  testRequests={tableDataRows}
                />
              </td>
            </tr>
          );
        }
      }
    }
    return itemRows;
  };

  return (
    <>
      <TransplantCentreFilterToolBar
        tableDataRows={tableDataRows}
        setFilteredTestRequestsTableData={setFilteredTestRequestsTableData}
        setExpandedRows={setExpandedRows}
        settings={{ type: false, grid: true }}
        transplantCentreTabType={TransplantCentreTabType.TestRequests}
      />
      <LoadingMessage isLoading={isLoading}>
        <div className="table-wrapper" style={{ maxHeight: '54vh' }}>
          <table className="table">
            <thead className="table-header table--sticky-header">
              <tr>
                <th>TC</th>
                <th className="test-requests__patientIdCell">Patient ID</th>
                <th>Type</th>
                <th className="test-requests__dateCell">Request Date</th>
                <th>Details</th>
                <th>EMDIS Registry</th>
                <th className="test-requests__gridCell">Grid</th>
                <th>Request Status</th>
                <th className="test-requests__dateCell">Sample Arrival Date</th>
                <th>Donor Status</th>
                <th className="test-requests__dateCell">TU Date</th>
                <th>View Results</th>
              </tr>
            </thead>
            <tbody>
              {testRequests && filteredTestRequestsTableData.length > 0 ? (
                filteredTestRequestsTableData.map((testRequest) => renderItem(testRequest))
              ) : (
                <tr>
                  <td colSpan={12} style={{ textAlign: 'center', padding: '20px' }}>
                    No requests found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </LoadingMessage>
    </>
  );
};

export default TestRequestsTable;
