import log from 'loglevel';
import {
  get,
  post,
  ErrorAction as ApiErrorAction,
  NormalizedPayload,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import { Actions } from './actionNames';
import { getPatientDetails } from '../../patient';
import { algorithmTypes, cordSet, cordSetList, donorSet, donorSetList, searchRequestSummary } from '../../core';
import Config from '../../config';
import { SearchRequestSelectors } from '../../donorMatchSearchRequests';
import donorTypes from '../../core/constants/donorTypes';
// eslint-disable-next-line import/no-cycle
import { convertSavedCordSetToApi, convertSavedDonorSetToApi } from '../helpers/apiDataConverter';

import type { Dispatch, DonorType, GetState } from '../../core/types';
import type { ApiAdultSearchResult, ApiCordSearchResult, ApiSearchMetrics } from '../types/api';
import type {
  AppliedFiltersMap,
  HideableColumn,
  MinFilterType,
  MismatchLocus,
  MismatchMax,
  ValuesFilterType,
} from '../types';
import type {
  ApiSavedCordSet,
  ApiSavedDonorSet,
  ApiSearchRequestSummary,
} from '../../donorMatchSearchRequests/types/api';
import type { SavedResultSet, SelectedResult } from '../../donorMatchSearchRequests/types';
import type { ReduxState } from '../../rootReducer';

type CordSetEntities = { SavedCordSets: { [string: string]: ApiSavedCordSet } };
type NormalizedCordSet = NormalizedPayload<string, CordSetEntities>;
type NormalizedCordSetList = NormalizedPayload<string[], CordSetEntities>;
type DonorSetEntities = { SavedDonorSets: { [string: string]: ApiSavedDonorSet } };
type NormalizedDonorSet = NormalizedPayload<string, DonorSetEntities>;
type NormalizedDonorSetList = NormalizedPayload<string[], DonorSetEntities>;
type SearchRequestSummaryEntities = {
  searchRequestSummaries: { [string: string]: ApiSearchRequestSummary };
};
type NormalizedSearchRequest = NormalizedPayload<string, SearchRequestSummaryEntities>;

type RequestIdParam = { requestId: string };
type ResultSetIdParam = { resultSetId: string };
type SuccessMessageParam = { successMessage: string };
type ErrorMessageParam = { errorMessage: string };
type Error = { Error: string };

export type AdultSearchResultsErrorAction = ApiErrorAction<typeof Actions.ADULT_SEARCH_RESULTS_ERROR, ResultSetIdParam>;
export type AdultSearchResultsRequestAction = ApiRequestAction<
  typeof Actions.ADULT_SEARCH_RESULTS_REQUEST,
  ResultSetIdParam
>;
export type AdultSearchResultsSuccessAction = ApiSuccessAction<
  typeof Actions.ADULT_SEARCH_RESULTS_SUCCESS,
  ResultSetIdParam,
  ApiAdultSearchResult[]
>;
export type AdultSearchResultsCreateDonorSetSuccessAction = ApiSuccessAction<
  typeof Actions.ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_SUCCESS,
  SuccessMessageParam & SuccessMessageParam,
  NormalizedDonorSet & SuccessMessageParam
>;
export type AdultSearchResultsSavedDonorsErrorAction = ApiErrorAction<
  typeof Actions.ADULT_SEARCH_RESULTS_DONOR_SETS_ERROR,
  RequestIdParam
>;

export type AdultSearchResultsSavedDonorsRequestAction = ApiRequestAction<
  typeof Actions.ADULT_SEARCH_RESULTS_DONOR_SETS_REQUEST,
  Record<string, unknown>
>;

export type AdultSearchResultsSavedDonorsSuccessAction = ApiSuccessAction<
  typeof Actions.ADULT_SEARCH_RESULTS_DONOR_SETS_SUCCESS,
  RequestIdParam,
  NormalizedDonorSetList
>;
export type AdultSearchResultsDonorSetCreateErrorAction = ApiErrorAction<
  typeof Actions.ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_ERROR,
  RequestIdParam & ErrorMessageParam,
  Error
>;
export type AdultSearchResultsDonorSetUpdateError = ApiErrorAction<
  typeof Actions.ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_ERROR,
  RequestIdParam & ErrorMessageParam,
  Error
>;
export type AdultSearchResultsUpdateDonorSetSuccessAction = ApiSuccessAction<
  typeof Actions.ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_SUCCESS,
  RequestIdParam & SuccessMessageParam,
  NormalizedDonorSet & SuccessMessageParam
>;

export type ClearFiltersAction = {
  type: typeof Actions.SEARCH_RESULTS_CLEAR_FILTERS;
};
export type CordSearchResultsErrorAction = ApiErrorAction<typeof Actions.CORD_SEARCH_RESULTS_ERROR, ResultSetIdParam>;
export type CordSearchResultsRequestAction = ApiRequestAction<
  typeof Actions.CORD_SEARCH_RESULTS_REQUEST,
  ResultSetIdParam
>;
export type CordSearchResultsSuccessAction = ApiSuccessAction<
  typeof Actions.CORD_SEARCH_RESULTS_SUCCESS,
  ResultSetIdParam,
  ApiCordSearchResult[]
>;
export type CordSearchResultsCordSetCreateErrorAction = ApiErrorAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SET_CREATE_ERROR,
  RequestIdParam & ErrorMessageParam,
  Error
>;
export type CordSearchResultsCordSetUpdateErrorAction = ApiErrorAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SET_UPDATE_ERROR,
  RequestIdParam & ErrorMessageParam,
  Error
>;
export type CordSearchResultsCordSetsRequestAction = ApiRequestAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SETS_REQUEST,
  RequestIdParam
>;
export type CordSearchResultsCreateCordSetSuccessAction = ApiSuccessAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SET_CREATE_SUCCESS,
  RequestIdParam & SuccessMessageParam,
  NormalizedCordSet & SuccessMessageParam
>;
export type CordSearchResultsSavedCordsErrorAction = ApiErrorAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SETS_ERROR,
  RequestIdParam
>;
export type CordSearchResultsSavedCordsRequestAction = ApiRequestAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SETS_REQUEST,
  RequestIdParam
>;
export type CordSearchResultsSavedCordsSuccessAction = ApiSuccessAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SETS_SUCCESS,
  RequestIdParam,
  NormalizedCordSetList
>;
export type CordSearchResultsUpdateCordSetSuccessAction = ApiSuccessAction<
  typeof Actions.CORD_SEARCH_RESULTS_CORD_SET_UPDATE_SUCCESS,
  RequestIdParam & SuccessMessageParam,
  NormalizedCordSet & SuccessMessageParam
>;

export type ResetResultsTableAction = {
  type: typeof Actions.SEARCH_RESULTS_RESET_RESULTS_TABLE;
};

export type SearchMetricsErrorAction = ApiErrorAction<
  typeof Actions.SEARCH_METRICS_ERROR,
  RequestIdParam & ResultSetIdParam
>;
export type SearchMetricsRequestAction = ApiRequestAction<
  typeof Actions.SEARCH_METRICS_REQUEST,
  Record<string, unknown>
>;

export type SearchMetricsSuccessAction = ApiSuccessAction<
  typeof Actions.SEARCH_METRICS_SUCCESS,
  RequestIdParam & ResultSetIdParam,
  ApiSearchMetrics
>;

export type SearchRequestsErrorAction = ApiErrorAction<
  typeof Actions.SEARCH_REQUESTS_ERROR,
  RequestIdParam,
  { message: string }
>;
export type SearchRequestsSuccessAction = ApiSuccessAction<
  typeof Actions.SEARCH_REQUESTS_SUCCESS,
  RequestIdParam,
  NormalizedSearchRequest
>;
export type SearchResultsRequestAction = ApiRequestAction<typeof Actions.SEARCH_RESULTS_REQUEST, RequestIdParam>;
export type SearchResultsSuccessAction = ApiSuccessAction<typeof Actions.SEARCH_RESULTS_SUCCESS, RequestIdParam>;
export type ShowSavedCordsSetAction = {
  type: typeof Actions.SEARCH_RESULTS_SHOW_SAVED_CORDS_SET;
  payload: string;
};

export type ShowSavedDonorsSetAction = {
  type: typeof Actions.SEARCH_RESULTS_SHOW_SAVED_DONORS_SET;
  payload: string;
};

export type UpdateAgeFilterAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_AGE_FILTER;
  payload: {
    maxAge?: number;
    minAge?: number;
  };
};

export type UpdateDateAddedAfterFilterAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_DONOR_ADDED_AFTER_FILTER;
  payload: {
    addedDateMin: string;
  };
};

export type UpdateExcludeDkmsUkFilterAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_EXCLUDE_DKMS_UK_FILTER;
  payload: {
    excludeDkmsUkDonors: boolean;
  };
};

export type ClearMismatchesFilterAction = {
  type: typeof Actions.SEARCH_RESULTS_CLEAR_MISMATCH_FILTER;
};

export type UpdateMismatchesFilterAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_MISMATCHES_FILTER;
  payload: {
    locus?: MismatchLocus | null | undefined;
    max?: MismatchMax | null | undefined;
    searchLociCount?: number | null | undefined;
  };
};

export type UpdateFiltersAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_FILTERS;
  isInternational: boolean;
  payload: AppliedFiltersMap;
};

export type UpdateValuesFilterAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_VALUES_FILTER;
  payload: {
    filter: ValuesFilterType;
    value: string[];
  };
};

export type UpdateMinFilterAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_MIN_FILTER;
  payload: {
    filter: MinFilterType;
    value?: number;
  };
};

export type UpdateHiddenColumnsAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_HIDDEN_COLUMNS;
  payload: HideableColumn[];
};

export type UpdateSelectedItemsAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_SELECTED_ITEMS;
  payload: Partial<SelectedResult>[];
};

export type UpdateSelectedNotesAction = {
  type: typeof Actions.SEARCH_RESULTS_UPDATE_SELECTED_DONOR_NOTES;
  payload: string;
};

const searchRequestRequest = () => ({
  type: Actions.SEARCH_RESULTS_REQUEST,
});
const searchRequestSuccess = () => ({
  type: Actions.SEARCH_RESULTS_SUCCESS,
});

export const clearFilters = (): ClearFiltersAction => ({
  type: Actions.SEARCH_RESULTS_CLEAR_FILTERS,
});

export const resetResultsTable = (): ResetResultsTableAction => ({
  type: Actions.SEARCH_RESULTS_RESET_RESULTS_TABLE,
});

const showSavedCordsSet = (setId: string): ShowSavedCordsSetAction => ({
  type: Actions.SEARCH_RESULTS_SHOW_SAVED_CORDS_SET,
  payload: setId,
});

const showSavedDonorsSet = (setId: string): ShowSavedDonorsSetAction => ({
  type: Actions.SEARCH_RESULTS_SHOW_SAVED_DONORS_SET,
  payload: setId,
});

const updateFilters = (filters: AppliedFiltersMap, isInternational: boolean): UpdateFiltersAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_FILTERS,
  isInternational,
  payload: filters,
});

export const updateSelectedItems = (selectedItems: Partial<SelectedResult>[]): UpdateSelectedItemsAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_SELECTED_ITEMS,
  payload: selectedItems,
});
export const updateSelectedNotes = (notes: string): UpdateSelectedNotesAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_SELECTED_DONOR_NOTES,
  payload: notes,
});

export const updateAgeFilter = (minAge?: number, maxAge?: number): UpdateAgeFilterAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_AGE_FILTER,
  payload: {
    maxAge,
    minAge,
  },
});

export const updateDateFilter = (addedDateMin: string): UpdateDateAddedAfterFilterAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_DONOR_ADDED_AFTER_FILTER,
  payload: { addedDateMin },
});

export const clearMismatchesFilter = (): ClearMismatchesFilterAction => ({
  type: Actions.SEARCH_RESULTS_CLEAR_MISMATCH_FILTER,
});

export const updateExcludeDkmsUkDonorsFilter = (excludeDkmsUkDonors: boolean): UpdateExcludeDkmsUkFilterAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_EXCLUDE_DKMS_UK_FILTER,
  payload: { excludeDkmsUkDonors },
});

export const updateMismatchesFilter = (
  locus?: MismatchLocus | null | undefined,
  max?: MismatchMax | null | undefined,
  searchLociCount?: number | null | undefined
): UpdateMismatchesFilterAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_MISMATCHES_FILTER,
  payload: {
    locus,
    max,
    searchLociCount,
  },
});

export const updateValuesFilter = (filter: ValuesFilterType, value: string[]): UpdateValuesFilterAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_VALUES_FILTER,
  payload: {
    filter,
    value,
  },
});

export const updateMinFilter = (filter: MinFilterType, value?: number): UpdateMinFilterAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_MIN_FILTER,
  payload: {
    filter,
    value,
  },
});

export const updateHiddenColumns = (hiddenColumns: HideableColumn[]): UpdateHiddenColumnsAction => ({
  type: Actions.SEARCH_RESULTS_UPDATE_HIDDEN_COLUMNS,
  payload: hiddenColumns,
});

export const showSavedResultSet =
  (donorType: DonorType, id?: string) => (dispatch: Dispatch<any>, getState: GetState) => {
    if (id) {
      const selectedSet = SearchRequestSelectors.getSingleSavedResultSet(getState(), donorType, id);
      if (selectedSet) {
        const isInternational = selectedSet.algorithmUsed === algorithmTypes.external;
        if (donorType === donorTypes.adult.value) {
          dispatch(showSavedDonorsSet(id));
        } else {
          dispatch(showSavedCordsSet(id));
        }
        dispatch(updateFilters(selectedSet.appliedFilters, isInternational));
        dispatch(updateSelectedItems(selectedSet.selectedResults));
        dispatch(updateSelectedNotes(selectedSet.notes));
      } else {
        log.warn(`Could not find SavedResultSet for ${donorType}, id: ${id}`);
      }
    }
  };

const getSearchRequestData = (requestId: string) =>
  get<ReduxState, ApiSearchRequestSummary>(
    `${Config().apiBaseUrl}search-requests/${requestId}`,
    Actions.SEARCH_REQUESTS,
    { requestId },
    searchRequestSummary
  );

const getCordSearchResultData = (resultSetId: string) =>
  get<ReduxState, ApiCordSearchResult[]>(
    `${Config().apiBaseUrl}cord-search-results/set/${resultSetId}`,
    Actions.CORD_SEARCH_RESULTS,
    { resultSetId }
  );

const getAdultSearchResultData = (resultSetId: string) =>
  get<ReduxState, ApiAdultSearchResult[]>(
    `${Config().apiBaseUrl}search-results/set/${resultSetId}`,
    Actions.ADULT_SEARCH_RESULTS,
    { resultSetId }
  );

const fetchResultsData = (resultSetId: string, donorType: DonorType) =>
  donorType === donorTypes.adult.value ? getAdultSearchResultData(resultSetId) : getCordSearchResultData(resultSetId);

const fetchRequestAndPatientData = (requestId: string) => async (dispatch: Dispatch<SearchRequestsSuccessAction>) => {
  const request = await dispatch(getSearchRequestData(requestId));
  if (request.response) {
    dispatch(getPatientDetails(request.response.body?.entities.searchRequestSummaries[requestId].PatientId as string));
  }
};

export const getRequestAndPatientData = (requestId: string) => fetchRequestAndPatientData(requestId);

const fetchSavedDonors = (requestId: string) =>
  get<ReduxState, NormalizedDonorSetList>(
    `${Config().apiBaseUrl}search-results/${requestId}/saved-donors`,
    Actions.ADULT_SEARCH_RESULTS_DONOR_SETS,
    { requestId },
    donorSetList
  );

const fetchSavedCords = (requestId: string) =>
  get<ReduxState, NormalizedCordSetList>(
    `${Config().apiBaseUrl}cord-search-results/${requestId}/saved-cords`,
    Actions.CORD_SEARCH_RESULTS_CORD_SETS,
    { requestId },
    cordSetList
  );

const getRequestResultData =
  (requestId: string, resultSetId: string, donorType: DonorType, savedSetId?: string) =>
  async (dispatch: Dispatch<any>) => {
    const getSavedSelection = () =>
      donorType === donorTypes.cord.value ? fetchSavedCords(requestId) : fetchSavedDonors(requestId);

    await Promise.all([
      dispatch(fetchRequestAndPatientData(requestId) as unknown as SearchRequestsSuccessAction),
      dispatch(fetchResultsData(resultSetId, donorType)).then(() => {
        dispatch(showSavedResultSet(donorType, savedSetId));
      }),
      dispatch(getSavedSelection()),
    ]);
  };

const getSearchMetrics = (resultSetId: string, isCordSearch: boolean) => {
  const routePrefix = isCordSearch ? 'cord-' : '';
  return get<ReduxState, ApiSearchMetrics>(
    `${Config().apiBaseUrl}${routePrefix}search-results/set/${resultSetId}/search-metrics`,
    Actions.SEARCH_METRICS,
    { resultSetId }
  );
};

const getMetricsAndResults = (resultSetId: string, donorType: DonorType) => {
  const isCord = donorType === donorTypes.cord.value;

  return (dispatch: Dispatch<any>) => {
    Promise.all([dispatch(getSearchMetrics(resultSetId, isCord)), dispatch(fetchResultsData(resultSetId, donorType))]);
  };
};

export const getFullResultsPageData =
  (requestId: string, donorType: DonorType, resultSetId: string, savedSetId?: string) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(searchRequestRequest());
    await dispatch(getRequestResultData(requestId, resultSetId, donorType, savedSetId));
    dispatch(searchRequestSuccess());
  };

export const getSearchMetricsAndResults = (resultSetId: string, donorType: DonorType) =>
  getMetricsAndResults(resultSetId, donorType);

const createSavedDonorSet = (donorJsonData: any) =>
  post<ReduxState, NormalizedDonorSet>(
    `${Config().apiBaseUrl}search-results/saved-donors`,
    Actions.ADULT_SEARCH_RESULTS_DONOR_SET_CREATE,
    {
      successMessage: 'Donor Selection Saved',
      errorMessage: 'Donor Selection Not Saved',
    },
    donorJsonData,
    donorSet
  );

const createSavedCordSet = (cordJsonData: any) =>
  post<ReduxState, NormalizedCordSet>(
    `${Config().apiBaseUrl}cord-search-results/saved-cords`,
    Actions.CORD_SEARCH_RESULTS_CORD_SET_CREATE,
    {
      successMessage: 'Cord Selection Saved',
      errorMessage: 'Cord Selection Not Saved',
    },
    cordJsonData,
    cordSet
  );

const updateSavedDonorSet = (donorSetData: any) =>
  post<ReduxState, NormalizedDonorSet>(
    `${Config().apiBaseUrl}search-results/saved-donors`,
    Actions.ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE,
    {
      successMessage: 'Donor Selection Updated',
      errorMessage: 'Donor Selection Not Updated',
    },
    donorSetData,
    donorSet
  );

const updateSavedCordSet = (cordSetData: any) =>
  post<ReduxState, NormalizedCordSet>(
    `${Config().apiBaseUrl}cord-search-results/saved-cords`,
    Actions.CORD_SEARCH_RESULTS_CORD_SET_UPDATE,
    {
      successMessage: 'Cord Selection Updated',
      errorMessage: 'Cord Selection Not Updated',
    },
    cordSetData,
    cordSet
  );

const saveOrUpdateSavedDonors = (donorsData: SavedResultSet) => {
  const donorApiData = convertSavedDonorSetToApi(donorsData);
  if (donorsData.id) {
    return updateSavedDonorSet(donorApiData);
  }
  return createSavedDonorSet(donorApiData);
};

const saveOrUpdateSavedCords = (cordsData: SavedResultSet) => {
  const cordApiData = convertSavedCordSetToApi(cordsData);
  if (cordsData.id) {
    return updateSavedCordSet(cordApiData);
  }
  return createSavedCordSet(cordApiData);
};

export const saveSelectedDonorsToServer = (dataToSave: SavedResultSet) => async (dispatch: Dispatch<any>) => {
  const donors = await dispatch(saveOrUpdateSavedDonors(dataToSave));

  if (donors.response.ok && donors.response.body) {
    dispatch(showSavedDonorsSet(donors.response.body.result));
  }
};

export const saveSelectedCordsToServer = (dataToSave: SavedResultSet) => async (dispatch: Dispatch<any>) => {
  const cords = await dispatch(saveOrUpdateSavedCords(dataToSave));

  if (cords.response.ok && cords.response.body) {
    dispatch(showSavedCordsSet(cords.response.body.result));
  }
};
