const ADULT_SEARCH_RESULTS: 'adultSearchResults' = 'adultSearchResults';
const ADULT_SEARCH_RESULTS_ERROR: 'adultSearchResults/error' = 'adultSearchResults/error';
const ADULT_SEARCH_RESULTS_REQUEST: 'adultSearchResults/request' = 'adultSearchResults/request';
const ADULT_SEARCH_RESULTS_SUCCESS: 'adultSearchResults/success' = 'adultSearchResults/success';

const ADULT_SEARCH_RESULTS_DONOR_SETS: 'adultSearchResults/donorsets' = 'adultSearchResults/donorsets';
const ADULT_SEARCH_RESULTS_DONOR_SETS_ERROR: 'adultSearchResults/donorsets/error' =
  'adultSearchResults/donorsets/error';
const ADULT_SEARCH_RESULTS_DONOR_SETS_REQUEST: 'adultSearchResults/donorsets/request' =
  'adultSearchResults/donorsets/request';
const ADULT_SEARCH_RESULTS_DONOR_SETS_SUCCESS: 'adultSearchResults/donorsets/success' =
  'adultSearchResults/donorsets/success';

const ADULT_SEARCH_RESULTS_DONOR_SET_CREATE: 'adultSearchResults/donorsets/create' =
  'adultSearchResults/donorsets/create';
const ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_ERROR: 'adultSearchResults/donorsets/create/error' =
  'adultSearchResults/donorsets/create/error';
const ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_REQUEST: 'adultSearchResults/donorsets/create/request' =
  'adultSearchResults/donorsets/create/request';
const ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_SUCCESS: 'adultSearchResults/donorsets/create/success' =
  'adultSearchResults/donorsets/create/success';

const ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE: 'adultSearchResults/donorsets/update' =
  'adultSearchResults/donorsets/update';
const ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_ERROR: 'adultSearchResults/donorsets/update/error' =
  'adultSearchResults/donorsets/update/error';
const ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_REQUEST: 'adultSearchResults/donorsets/update/request' =
  'adultSearchResults/donorsets/update/request';
const ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_SUCCESS: 'adultSearchResults/donorsets/update/success' =
  'adultSearchResults/donorsets/update/success';

const CORD_SEARCH_RESULTS: 'cordSearchResults' = 'cordSearchResults';
const CORD_SEARCH_RESULTS_ERROR: 'cordSearchResults/error' = 'cordSearchResults/error';
const CORD_SEARCH_RESULTS_REQUEST: 'cordSearchResults/request' = 'cordSearchResults/request';
const CORD_SEARCH_RESULTS_SUCCESS: 'cordSearchResults/success' = 'cordSearchResults/success';

const CORD_SEARCH_RESULTS_CORD_SETS: 'cordSearchResults/cordsets' = 'cordSearchResults/cordsets';
const CORD_SEARCH_RESULTS_CORD_SETS_ERROR: 'cordSearchResults/cordsets/error' = 'cordSearchResults/cordsets/error';
const CORD_SEARCH_RESULTS_CORD_SETS_REQUEST: 'cordSearchResults/cordsets/request' =
  'cordSearchResults/cordsets/request';
const CORD_SEARCH_RESULTS_CORD_SETS_SUCCESS: 'cordSearchResults/cordsets/success' =
  'cordSearchResults/cordsets/success';

const CORD_SEARCH_RESULTS_CORD_SET_CREATE: 'cordSearchResults/cordsets/create' = 'cordSearchResults/cordsets/create';
const CORD_SEARCH_RESULTS_CORD_SET_CREATE_ERROR: 'cordSearchResults/cordsets/create/error' =
  'cordSearchResults/cordsets/create/error';
const CORD_SEARCH_RESULTS_CORD_SET_CREATE_REQUEST: 'cordSearchResults/cordsets/create/request' =
  'cordSearchResults/cordsets/create/request';
const CORD_SEARCH_RESULTS_CORD_SET_CREATE_SUCCESS: 'cordSearchResults/cordsets/create/success' =
  'cordSearchResults/cordsets/create/success';

const CORD_SEARCH_RESULTS_CORD_SET_UPDATE: 'cordSearchResults/cordsets/update' = 'cordSearchResults/cordsets/update';
const CORD_SEARCH_RESULTS_CORD_SET_UPDATE_ERROR: 'cordSearchResults/cordsets/update/error' =
  'cordSearchResults/cordsets/update/error';
const CORD_SEARCH_RESULTS_CORD_SET_UPDATE_REQUEST: 'cordSearchResults/cordsets/update/request' =
  'cordSearchResults/cordsets/update/request';
const CORD_SEARCH_RESULTS_CORD_SET_UPDATE_SUCCESS: 'cordSearchResults/cordsets/update/success' =
  'cordSearchResults/cordsets/update/success';

const SEARCH_METRICS: 'searchMetrics' = 'searchMetrics';
const SEARCH_METRICS_ERROR: 'searchMetrics/error' = 'searchMetrics/error';
const SEARCH_METRICS_REQUEST: 'searchMetrics/request' = 'searchMetrics/request';
const SEARCH_METRICS_SUCCESS: 'searchMetrics/success' = 'searchMetrics/success';

const SEARCH_REQUESTS: 'searchRequests' = 'searchRequests';
const SEARCH_REQUESTS_ERROR: 'searchRequests/error' = 'searchRequests/error';
const SEARCH_REQUESTS_REQUEST: 'searchRequests/request' = 'searchRequests/request';
const SEARCH_REQUESTS_SUCCESS: 'searchRequests/success' = 'searchRequests/success';

const SEARCH_RESULTS_REQUEST: 'searchResults/request' = 'searchResults/request';
const SEARCH_RESULTS_SUCCESS: 'searchResults/success' = 'searchResults/success';

const SEARCH_RESULTS_CLEAR_FILTERS: 'searchResults/filter/clear' = 'searchResults/filter/clear';
const SEARCH_RESULTS_CLEAR_MISMATCH_FILTER: 'searchResults/filter/mismatch/clear' =
  'searchResults/filter/mismatch/clear';

const SEARCH_RESULTS_RESET_RESULTS_TABLE: 'searchResults/reset' = 'searchResults/reset';
const SEARCH_RESULTS_SHOW_SAVED_CORDS_SET: 'searchResults/savedCordsSet/show' = 'searchResults/savedCordsSet/show';
const SEARCH_RESULTS_SHOW_SAVED_DONORS_SET: 'searchResults/savedDonorsSet/show' = 'searchResults/savedDonorsSet/show';
const SEARCH_RESULTS_UPDATE_AGE_FILTER: 'searchResults/filter/age/updated' = 'searchResults/filter/age/updated';
const SEARCH_RESULTS_UPDATE_DONOR_ADDED_AFTER_FILTER: 'searchResults/filter/donorAddedAfter/updated' =
  'searchResults/filter/donorAddedAfter/updated';
const SEARCH_RESULTS_UPDATE_EXCLUDE_DKMS_UK_FILTER: 'searchResults/filter/excludeDkmsUk/updated' =
  'searchResults/filter/excludeDkmsUk/updated';
const SEARCH_RESULTS_UPDATE_FILTERS: 'searchResults/filters/all/update' = 'searchResults/filters/all/update';
const SEARCH_RESULTS_UPDATE_MIN_FILTER: 'searchResults/filter/min/updated' = 'searchResults/filter/min/updated';
const SEARCH_RESULTS_UPDATE_MISMATCHES_FILTER: 'searchResults/filter/mismatches/updated' =
  'searchResults/filter/mismatches/updated';
const SEARCH_RESULTS_UPDATE_VALUES_FILTER: 'searchResults/filter/values/updated' =
  'searchResults/filter/values/updated';
const SEARCH_RESULTS_UPDATE_HIDDEN_COLUMNS: 'searchResults/hiddenColumns/update' = 'searchResults/hiddenColumns/update';
const SEARCH_RESULTS_UPDATE_SELECTED_ITEMS: 'searchResults/selectedItems/update' = 'searchResults/selectedItems/update';
const SEARCH_RESULTS_UPDATE_SELECTED_DONOR_NOTES: 'searchResults/selectedNotes/update' =
  'searchResults/selectedNotes/update';

export const Actions = {
  ADULT_SEARCH_RESULTS,
  ADULT_SEARCH_RESULTS_ERROR,
  ADULT_SEARCH_RESULTS_REQUEST,
  ADULT_SEARCH_RESULTS_SUCCESS,

  ADULT_SEARCH_RESULTS_DONOR_SET_CREATE,
  ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_ERROR,
  ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_REQUEST,
  ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_SUCCESS,

  ADULT_SEARCH_RESULTS_DONOR_SETS,
  ADULT_SEARCH_RESULTS_DONOR_SETS_ERROR,
  ADULT_SEARCH_RESULTS_DONOR_SETS_REQUEST,
  ADULT_SEARCH_RESULTS_DONOR_SETS_SUCCESS,

  ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE,
  ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_ERROR,
  ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_REQUEST,
  ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_SUCCESS,

  CORD_SEARCH_RESULTS,
  CORD_SEARCH_RESULTS_ERROR,
  CORD_SEARCH_RESULTS_REQUEST,
  CORD_SEARCH_RESULTS_SUCCESS,

  CORD_SEARCH_RESULTS_CORD_SET_CREATE,
  CORD_SEARCH_RESULTS_CORD_SET_CREATE_ERROR,
  CORD_SEARCH_RESULTS_CORD_SET_CREATE_REQUEST,
  CORD_SEARCH_RESULTS_CORD_SET_CREATE_SUCCESS,

  CORD_SEARCH_RESULTS_CORD_SETS,
  CORD_SEARCH_RESULTS_CORD_SETS_ERROR,
  CORD_SEARCH_RESULTS_CORD_SETS_REQUEST,
  CORD_SEARCH_RESULTS_CORD_SETS_SUCCESS,

  CORD_SEARCH_RESULTS_CORD_SET_UPDATE,
  CORD_SEARCH_RESULTS_CORD_SET_UPDATE_ERROR,
  CORD_SEARCH_RESULTS_CORD_SET_UPDATE_REQUEST,
  CORD_SEARCH_RESULTS_CORD_SET_UPDATE_SUCCESS,

  SEARCH_METRICS,
  SEARCH_METRICS_ERROR,
  SEARCH_METRICS_REQUEST,
  SEARCH_METRICS_SUCCESS,

  SEARCH_REQUESTS,
  SEARCH_REQUESTS_ERROR,
  SEARCH_REQUESTS_REQUEST,
  SEARCH_REQUESTS_SUCCESS,

  SEARCH_RESULTS_REQUEST,
  SEARCH_RESULTS_SUCCESS,

  SEARCH_RESULTS_CLEAR_FILTERS,
  SEARCH_RESULTS_CLEAR_MISMATCH_FILTER,

  SEARCH_RESULTS_RESET_RESULTS_TABLE,
  SEARCH_RESULTS_SHOW_SAVED_CORDS_SET,
  SEARCH_RESULTS_SHOW_SAVED_DONORS_SET,

  SEARCH_RESULTS_UPDATE_AGE_FILTER,
  SEARCH_RESULTS_UPDATE_DONOR_ADDED_AFTER_FILTER,
  SEARCH_RESULTS_UPDATE_EXCLUDE_DKMS_UK_FILTER,
  SEARCH_RESULTS_UPDATE_FILTERS,
  SEARCH_RESULTS_UPDATE_MIN_FILTER,
  SEARCH_RESULTS_UPDATE_MISMATCHES_FILTER,
  SEARCH_RESULTS_UPDATE_VALUES_FILTER,

  SEARCH_RESULTS_UPDATE_HIDDEN_COLUMNS,
  SEARCH_RESULTS_UPDATE_SELECTED_ITEMS,
  SEARCH_RESULTS_UPDATE_SELECTED_DONOR_NOTES,
};
