import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../redux/actions';
import Selectors from '../../core/redux/selectors';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  searchedPatientId: string;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchedPatientId: Selectors.getSearchedPatientId(state) as string,
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getPatientDetails: bindActionCreators(actions.getPatientDetails, dispatch),
  setSearchPatientId: bindActionCreators(actions.setSearchPatientId, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type InputEvent = { value: string } & HTMLInputElement;

class SearchByIdForm extends PureComponent<Props, { patientId: string }> {
  constructor(props: Props) {
    super(props);
    const { searchedPatientId } = this.props;
    this.state = {
      patientId: searchedPatientId,
    };
  }

  render() {
    const { patientId } = this.state;
    return (
      <form onSubmit={this.getPatient} style={{ marginTop: '10px' }}>
        <input type="text" onChange={this.handlePatientIdInput} value={patientId} placeholder="Patient Id" />
        <button type="submit" className="btn btn--inline">
          Search
        </button>
      </form>
    );
  }

  getPatient = (event: React.MouseEvent<HTMLFormElement>) => {
    const { getPatientDetails, setSearchPatientId } = this.props;
    const { patientId } = this.state;
    event.preventDefault();
    if (!patientId) {
      return setSearchPatientId(patientId);
    }
    return getPatientDetails(patientId);
  };

  handlePatientIdInput = (event: React.SyntheticEvent<InputEvent>) => {
    this.setState({ patientId: event.currentTarget.value });
  };
}

export default connector(SearchByIdForm);
