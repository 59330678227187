export const ApiRepeatSearchStatuses = {
  Active: 'Active',
  Deactivated: 'Deactivated',
  Failed: 'FirstSearchFailed',
  Initiated: 'FirstSearchInitiated',
} as const;

export const RepeatSearchStatuses = {
  Active: 'Active',
  Deactivated: 'Deactivated',
  Failed: 'Failed',
  Initiated: 'Initiated',
} as const;

export type RepeatSearchStatus = typeof RepeatSearchStatuses[keyof typeof RepeatSearchStatuses];

export type ApiRepeatSearchStatus = typeof ApiRepeatSearchStatuses[keyof typeof ApiRepeatSearchStatuses];

export const convertRepeatSearchStatusFromApi = (apiStatus: ApiRepeatSearchStatus): RepeatSearchStatus => {
  switch (apiStatus) {
    case ApiRepeatSearchStatuses.Active:
      return RepeatSearchStatuses.Active;
    case ApiRepeatSearchStatuses.Deactivated:
      return RepeatSearchStatuses.Deactivated;
    case ApiRepeatSearchStatuses.Failed:
      return RepeatSearchStatuses.Failed;
    case ApiRepeatSearchStatuses.Initiated:
      return RepeatSearchStatuses.Initiated;
    default:
      return apiStatus;
  }
};
