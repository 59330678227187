import _ from 'lodash';
import { shouldIncludeDonorDueToMismatches } from './mismatchFilterHelper';
import { shouldIncludeDkmsUkDonor } from './shouldIncludeDkmsUkDonor';
import { getLocusMatchCategoryFilterOptions } from '../constants/locusMatchCategories';
import donorTypes from '../../core/constants/donorTypes';
import { filterNames } from '../constants/filterNames';
import type { AgeFilter, AppliedFiltersArray, MinFilter, ValuesFilter } from '../types';
import type { AdultDonor, AdultSearchResult, CordSearchResult, SearchResults, Option } from '../../core/types';

export const aboOptions: Option[] = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'AB', label: 'AB' },
  { value: 'O', label: 'O' },
  { value: 'Unknown', label: 'Unknown' },
];

export const options: Option[] = [
  { value: 'Negative', label: 'Negative' },
  { value: 'Positive', label: 'Positive' },
  { value: 'Unknown', label: 'Unknown' },
];

export const cmvOptions: Option[] = [...options, { value: 'Equivocal', label: 'Equivocal' }];

export const dpb1MatchCategoryOptions: Option[] = getLocusMatchCategoryFilterOptions();

export const genderOptions: Option[] = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

export const donorIdOptions = (searchResults: AdultSearchResult[]): Option[] =>
  searchResults.map((result) => ({
    value: result.donor.homeRegistryId,
    label: result.donor.homeRegistryId,
  }));

export const gridOptions = (searchResults: AdultSearchResult[]): Option[] =>
  searchResults
    .filter((result) => result.donor.grid)
    .map((result) => ({
      value: result.donor.grid,
      label: result.donor.grid || '',
    })) || [];

export const registryOptions = (searchResults: AdultSearchResult[]): Option[] => {
  const registryList = searchResults.map((result) => result.donor.originatingRegistry);

  const uniqueRegistryList = registryList.filter((s1, pos, arr) => arr.findIndex((s2) => s2.id === s1.id) === pos);

  return uniqueRegistryList.map((registry) => ({
    value: registry.id,
    label: registry.name,
  }));
};

export const mismatchOptionsForNovaScoring = [
  { value: 'A', label: 'HLA-A' },
  { value: 'B', label: 'HLA-B' },
  { value: 'C', label: 'HLA-C' },
  { value: 'Drb1', label: 'HLA-DRB1' },
  { value: 'Dqb1', label: 'HLA-DQB1' },
  { value: null, label: 'None' },
];

export const shouldInclude = (filter: ValuesFilter, value?: string | number): boolean => {
  if (filter.value.length === 0) {
    return true;
  }
  // @ts-expect-error, includes method receives a string as parameter, value is string | number
  return filter.value.includes(value);
};

const shouldIncludeCordDueToMin = (rowValue: number, filter: MinFilter): boolean => {
  const { min } = filter.value;
  if (!min) {
    return true;
  }

  return rowValue >= min;
};

export const shouldIncludeDonor = (donor: AdultDonor, type: string, filter: ValuesFilter) => {
  if (filter.value.length === 0) {
    return true;
  }
  const filterValue = type === 'originatingRegistry' ? _.get(donor, type).id : _.get(donor, type);
  return filter.value.includes(filterValue);
};

export const shouldIncludeDonorDueToAge = (donor: AdultDonor, filter: AgeFilter): boolean => {
  const { maxAge, minAge } = filter.value;
  if (!minAge && !maxAge) {
    return true;
  }

  if (!maxAge) {
    // $FlowExpectedError - not recognising that `maxAge` will always be populated
    return donor.age >= (minAge as number);
  }

  if (!minAge) {
    return donor.age <= maxAge;
  }

  return donor.age >= minAge && donor.age <= maxAge;
};

export const shouldIncludeDate = (dateValue: string, dateFilter: string) => {
  const filterDate = new Date(dateFilter);
  const valueDate = new Date(dateValue);

  return valueDate >= filterDate;
};

const applyAdultFilters = (
  filters: AppliedFiltersArray,
  results: AdultSearchResult[],
  lociExcludedFromMismatchFilter: string[]
): AdultSearchResult[] => {
  let filteredResults = [...results];

  filters.forEach((filter) => {
    if (filter?.name === filterNames.abo) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.donor.abo));
    } else if (filter?.name === filterNames.age) {
      filteredResults = filteredResults.filter((result) => shouldIncludeDonorDueToAge(result.donor, filter));
    } else if (filter?.name === filterNames.cmv) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.donor.cmv));
    } else if (filter?.name === filterNames.dpb1MatchCategories) {
      filteredResults = filteredResults.filter((result) =>
        shouldInclude(filter, result.locusDpb1Info?.matchCategory?.toString())
      );
    } else if (filter?.name === filterNames.gender) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.donor.gender));
    } else if (filter?.name === filterNames.grid) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.donor.grid));
    } else if (filter?.name === filterNames.homeRegistryId) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.donor.homeRegistryId));
    } else if (filter?.name === filterNames.mismatches) {
      filteredResults = filteredResults.filter((result) =>
        shouldIncludeDonorDueToMismatches(result, filter.value, lociExcludedFromMismatchFilter)
      );
    } else if (filter?.name === filterNames.originatingRegistryId) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.donor.originatingRegistry.id));
    } else if (filter?.name === filterNames.rhd) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.donor.rhd));
    } else if (filter?.name === filterNames.excludeDkmsUkDonors) {
      filteredResults = filteredResults.filter((result) => shouldIncludeDkmsUkDonor(result.donor.grid, filter.value));
    } else if (filter?.name === filterNames.addedDateMin) {
      filteredResults = filteredResults.filter((result) => shouldIncludeDate(result.addedDateUtc, filter.value));
    }
  });
  return filteredResults;
};

const applyCordFilters = (filters: AppliedFiltersArray, results: CordSearchResult[]): CordSearchResult[] => {
  let filteredResults = [...results];

  filters.forEach((filter) => {
    if (filter?.name === filterNames.abo) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.cord.bloodGroup));
    } else if (filter?.name === filterNames.cd34) {
      filteredResults = filteredResults.filter((result) =>
        shouldIncludeCordDueToMin(result.cord.cd34CellCount, filter)
      );
    } else if (filter?.name === filterNames.cmv) {
      filteredResults = filteredResults.filter((result) => shouldInclude(filter, result.cord.cmvAntibodyType));
    } else if (filter?.name === filterNames.tnc) {
      filteredResults = filteredResults.filter((result) =>
        shouldIncludeCordDueToMin(result.cord.tncFinalCount, filter)
      );
    }
  });

  return filteredResults;
};

export const applyFilters = (
  filters: AppliedFiltersArray,
  searchResults: SearchResults,
  lociExcludedFromMismatchFilter: string[]
) => {
  if (searchResults.type === donorTypes.adult.value) {
    const filteredResults: AdultSearchResult[] = applyAdultFilters(
      filters,
      searchResults.results as AdultSearchResult[],
      lociExcludedFromMismatchFilter
    );
    return {
      results: filteredResults,
      resultSetId: searchResults.resultSetId,
      type: searchResults.type,
    };
  }

  const filteredResults: CordSearchResult[] = applyCordFilters(filters, searchResults.results as CordSearchResult[]);
  return {
    results: filteredResults,
    resultSetId: searchResults.resultSetId,
    type: searchResults.type,
  };
};
