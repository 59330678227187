import React, { CSSProperties } from 'react';
import moment from 'moment';
import * as FilterHelper from '../../../helpers/filterHelper';
import ValuesFilter from './ValuesFilter/ValuesFilter';
import AgeFilter from './AgeFilter/AgeFilter';
import MismatchesFilter from './MismatchesFilter/MismatchesFilter';
import { DkmsUkDonorsFilter } from './DkmsUkDonorsFilter';
import ClearFilters from './ClearFilters/ClearFilters';
import { styles } from '../../../style';
import type { AdultSearchResults } from '../../../../core/types';
import { filterNames } from '../../../constants/filterNames';
import FeatureFlag from '../../../../featureFlags/components/FeatureFlag';
import DateFilter from './DateFilter/DateFilter';
import { TableContents } from '../../ResultsTable/searchResultsTableContents';

type Props = {
  isExternal: boolean;
  searchResults: AdultSearchResults;
  tableContents: TableContents<any>;
};

const externalResultsStyles: CSSProperties = {
  maxHeight: '700px',
  overflow: 'auto',
};

export default (props: Props) => {
  const { isExternal, searchResults, tableContents } = props;
  const hasAddedDateColumn = tableContents.columns.some((column) => column.name === 'Added');

  return (
    <div style={isExternal ? externalResultsStyles : undefined}>
      <div className="col span_12_of_12">
        <div className="col span_4_of_12">
          <ValuesFilter
            filterType={filterNames.gender}
            filterName="Gender"
            selectOptions={FilterHelper.genderOptions}
          />
        </div>
        <div className="col span_4_of_12">
          <ValuesFilter filterType={filterNames.abo} filterName="ABO" isMulti selectOptions={FilterHelper.aboOptions} />
        </div>
        <div className="col span_4_of_12">
          <ValuesFilter filterType={filterNames.rhd} filterName="RhD" isMulti selectOptions={FilterHelper.options} />
        </div>
      </div>
      <div className="col span_12_of_12">
        <div className="col span_4_of_12">
          <ValuesFilter filterType={filterNames.cmv} filterName="CMV" isMulti selectOptions={FilterHelper.cmvOptions} />
        </div>
        <div className="col span_6_of_12">
          <AgeFilter />
        </div>
      </div>
      <div className="col span_12_of_12">
        <FeatureFlag
          andFlags={['dpb1Filtering']}
          render={() =>
            !isExternal && (
              <div className="col span_4_of_12">
                <ValuesFilter
                  isMulti
                  filterType={filterNames.dpb1MatchCategories}
                  filterName="DPB1 Match Category"
                  selectOptions={FilterHelper.dpb1MatchCategoryOptions}
                />
              </div>
            )
          }
        />
        {hasAddedDateColumn && (
          <div className="col span_4_of_12">
            <DateFilter filterName="Donor Added After" max={moment().format('YYYY-MM-DD')} />
          </div>
        )}
      </div>

      {isExternal && (
        <>
          <div className="col span_12_of_12">
            <div className="col span_6_of_12">
              <ValuesFilter
                filterType={filterNames.grid}
                filterName="GRID"
                isMulti
                selectOptions={FilterHelper.gridOptions(searchResults.results)}
              />
            </div>
            <div className="col span_4_of_12">
              <ValuesFilter
                filterType={filterNames.homeRegistryId}
                filterName="External Donor ID"
                isMulti
                selectOptions={FilterHelper.donorIdOptions(searchResults.results)}
              />
            </div>
          </div>
          <div className="col span_12_of_12">
            <div className="col span_6_of_12">
              <ValuesFilter
                filterType={filterNames.originatingRegistryId}
                filterName="Registry"
                isMulti
                selectOptions={FilterHelper.registryOptions(searchResults.results)}
              />
            </div>
            <div style={styles.dkmsUkDonorsFilterContainer} className="col span_6_of_12">
              <DkmsUkDonorsFilter />
            </div>
          </div>
          <div className="col span_12_of_12">
            <MismatchesFilter selectOptions={FilterHelper.mismatchOptionsForNovaScoring} />
          </div>
        </>
      )}
      <div className="col span_12_of_12">
        <div className="col span_2_of_12 push_5" style={styles.flexFilterButton}>
          <ClearFilters />
        </div>
      </div>
    </div>
  );
};
