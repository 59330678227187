/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  ErrorAction as ApiErrorAction,
  get,
  post,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
  Response as ApiResponse,
} from '@an/nova-frontend-rest-client';
import UrlAssembler from 'url-assembler';
import Config from '../../config';
import type { Dispatch } from '../../core/types';
// eslint-disable-next-line import/no-cycle
import { ReduxState } from '../../rootReducer';
import {
  ApiExternalInvestigationAction,
  ApiMessageDenialAction,
  ApiRepeatUKSearch,
  ApiUpdatedActionsResponse,
} from '../types/api';

const EXTERNAL_INVESTIGATION_ACTIONS: 'externalInvestigationActions' = 'externalInvestigationActions';
const EXTERNAL_INVESTIGATION_ACTIONS_ERROR: 'externalInvestigationActions/error' = 'externalInvestigationActions/error';
const EXTERNAL_INVESTIGATION_ACTIONS_REQUEST: 'externalInvestigationActions/request' =
  'externalInvestigationActions/request';
const EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS: 'externalInvestigationActions/success' =
  'externalInvestigationActions/success';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS: 'updateExternalInvestigationActions' =
  'updateExternalInvestigationActions';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_REQUEST: 'updateExternalInvestigationActions/request' =
  'updateExternalInvestigationActions/request';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS: 'updateExternalInvestigationActions/success' =
  'updateExternalInvestigationActions/success';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_ERROR: 'updateExternalInvestigationActions/error' =
  'updateExternalInvestigationActions/error';
const UPDATED_ACTIONS_RESPONSE_SUCCESS: 'updatedActionsResponse/success' = 'updatedActionsResponse/success';
const UPDATED_ACTIONS_RESPONSE_ERROR: 'updatedActionsResponse/error' = 'updatedActionsResponse/error';

const MESSAGE_DENIAL_ACTIONS: 'rejectedMessagesActions' = 'rejectedMessagesActions';
const MESSAGE_DENIAL_ACTIONS_REQUEST: 'rejectedMessagesActions/request' = 'rejectedMessagesActions/request';
const MESSAGE_DENIAL_ACTIONS_SUCCESS: 'rejectedMessagesActions/success' = 'rejectedMessagesActions/success';
const MESSAGE_DENIAL_ACTIONS_ERROR: 'rejectedMessagesActions/error' = 'rejectedMessagesActions/error';
const UPDATE_MESSAGE_DENIAL_ACTIONS: 'updateRejectedMessagesActions' = 'updateRejectedMessagesActions';
const UPDATE_MESSAGE_DENIAL_ACTIONS_REQUEST: 'updateRejectedMessagesActions/request' =
  'updateRejectedMessagesActions/request';
const UPDATE_MESSAGE_DENIAL_ACTIONS_SUCCESS: 'updateRejectedMessagesActions/success' =
  'updateRejectedMessagesActions/success';
const UPDATE_MESSAGE_DENIAL_ACTIONS_ERROR: 'updateRejectedMessagesActions/error' =
  'updateRejectedMessagesActions/error';

const REPEAT_UK_SEARCHES: 'repeatUKSearches' = 'repeatUKSearches';
const REPEAT_UK_SEARCHES_REQUEST: 'repeatUKSearches/request' = 'repeatUKSearches/request';
const REPEAT_UK_SEARCHES_SUCCESS: 'repeatUKSearches/success' = 'repeatUKSearches/success';
const REPEAT_UK_SEARCHES_ERROR: 'repeatUKSearches/error' = 'repeatUKSearches/error';
const UPDATE_REPEAT_UK_SEARCH_ACTIONS: 'updateRepeatUKSearchActions' = 'updateRepeatUKSearchActions';
const UPDATE_REPEAT_UK_SEARCH_ACTIONS_REQUEST: 'updateRepeatUKSearchActions/request' =
  'updateRepeatUKSearchActions/request';
const UPDATE_REPEAT_UK_SEARCH_ACTIONS_SUCCESS: 'updateRepeatUKSearchActions/success' =
  'updateRepeatUKSearchActions/success';
const UPDATE_REPEAT_UK_SEARCH_ACTIONS_ERROR: 'updateRepeatUKSearchActions/error' = 'updateRepeatUKSearchActions/error';

export const Actions = {
  EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  MESSAGE_DENIAL_ACTIONS_REQUEST,
  MESSAGE_DENIAL_ACTIONS_SUCCESS,
  MESSAGE_DENIAL_ACTIONS_ERROR,
  UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  UPDATE_MESSAGE_DENIAL_ACTIONS_REQUEST,
  UPDATE_MESSAGE_DENIAL_ACTIONS_SUCCESS,
  UPDATE_MESSAGE_DENIAL_ACTIONS_ERROR,
  UPDATED_ACTIONS_RESPONSE_SUCCESS,
  UPDATED_ACTIONS_RESPONSE_ERROR,
  REPEAT_UK_SEARCHES_REQUEST,
  REPEAT_UK_SEARCHES_SUCCESS,
  REPEAT_UK_SEARCHES_ERROR,
  UPDATE_REPEAT_UK_SEARCH_ACTIONS_REQUEST,
  UPDATE_REPEAT_UK_SEARCH_ACTIONS_SUCCESS,
  UPDATE_REPEAT_UK_SEARCH_ACTIONS_ERROR,
};

type ErrorMessageParam = { errorMessage: string };
type SuccessMessageParam = { successMessage: string };
type Error = { Error: string };

export type ExternalInvestigationActionsRequest = ApiRequestAction<
  typeof EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type ExternalInvestigationActionsSuccess = ApiSuccessAction<
  typeof EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  Record<string, unknown>,
  ApiExternalInvestigationAction[]
>;
export type ExternalInvestigationActionsError = ApiErrorAction<
  typeof EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  Record<string, unknown>
>;
export type UpdateExternalInvestigationActionsRequest = ApiRequestAction<
  typeof UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type UpdateExternalInvestigationActionsSuccess = ApiSuccessAction<
  typeof UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  SuccessMessageParam
>;
export type UpdateExternalInvestigationActionsError = ApiErrorAction<
  typeof UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;
export type UpdatedActionsResponseSuccess = {
  type: typeof UPDATED_ACTIONS_RESPONSE_SUCCESS;
  payload: {
    params: SuccessMessageParam;
  };
};
export type UpdatedActionsResponseError = {
  type: typeof UPDATED_ACTIONS_RESPONSE_ERROR;
  payload: {
    params: ErrorMessageParam;
  };
};

export type ExternalInvestigationAction =
  | ExternalInvestigationActionsRequest
  | ExternalInvestigationActionsSuccess
  | ExternalInvestigationActionsError
  | UpdateExternalInvestigationActionsRequest
  | UpdateExternalInvestigationActionsSuccess
  | UpdateExternalInvestigationActionsError
  | UpdatedActionsResponseSuccess
  | UpdatedActionsResponseError;

export type MessageDenialActionsRequest = ApiRequestAction<
  typeof MESSAGE_DENIAL_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type MessageDenialActionsSuccess = ApiSuccessAction<
  typeof MESSAGE_DENIAL_ACTIONS_SUCCESS,
  Record<string, unknown>,
  ApiMessageDenialAction[]
>;
export type MessageDenialActionsError = ApiErrorAction<typeof MESSAGE_DENIAL_ACTIONS_ERROR, Record<string, unknown>>;
export type UpdateMessageDenialsActionsRequest = ApiRequestAction<
  typeof UPDATE_MESSAGE_DENIAL_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type UpdateMessageDenialsActionsSuccess = ApiSuccessAction<
  typeof UPDATE_MESSAGE_DENIAL_ACTIONS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  SuccessMessageParam
>;
export type UpdateMessageDenialsActionsError = ApiErrorAction<
  typeof UPDATE_MESSAGE_DENIAL_ACTIONS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type RejectedMessageReduxAction =
  | MessageDenialActionsRequest
  | MessageDenialActionsSuccess
  | MessageDenialActionsError
  | UpdateMessageDenialsActionsRequest
  | UpdateMessageDenialsActionsSuccess
  | UpdateMessageDenialsActionsError;

export type RepeatUKSearchesRequest = ApiRequestAction<typeof REPEAT_UK_SEARCHES_REQUEST, Record<string, unknown>>;
export type RepeatUKSearchesSuccess = ApiSuccessAction<
  typeof REPEAT_UK_SEARCHES_SUCCESS,
  Record<string, unknown>,
  ApiRepeatUKSearch[]
>;
export type RepeatUKSearchesError = ApiErrorAction<typeof REPEAT_UK_SEARCHES_ERROR, Record<string, unknown>>;
export type UpdateRepeatUKSearchActionsRequest = ApiRequestAction<
  typeof UPDATE_REPEAT_UK_SEARCH_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type UpdateRepeatUKSearchActionsSuccess = ApiSuccessAction<
  typeof UPDATE_REPEAT_UK_SEARCH_ACTIONS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  SuccessMessageParam
>;
export type UpdateRepeatUKSearchActionsError = ApiErrorAction<
  typeof UPDATE_REPEAT_UK_SEARCH_ACTIONS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type RepeatUKSearchAction =
  | RepeatUKSearchesRequest
  | RepeatUKSearchesSuccess
  | RepeatUKSearchesError
  | UpdateRepeatUKSearchActionsRequest
  | UpdateRepeatUKSearchActionsSuccess
  | UpdateRepeatUKSearchActionsError;

export enum ActionType {
  TestRequest,
  MessageDenial,
  RepeatUKSearch,
}

export const getExternalInvestigationActions = () => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiExternalInvestigationAction>(
      `${Config().apiBaseUrl}actions/external-investigations`,
      EXTERNAL_INVESTIGATION_ACTIONS
    )
  );
};

export const getExternalInvestigationActionsByDate = (date: string) => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiExternalInvestigationAction>(
      `${Config().apiBaseUrl}actions/external-investigations?isActioned=true&updatedDate=${date}`,
      EXTERNAL_INVESTIGATION_ACTIONS
    )
  );
};

export const getMessageDenialActions = () => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiMessageDenialAction[]>(`${Config().apiBaseUrl}actions/message-denials`, MESSAGE_DENIAL_ACTIONS)
  );
};

export const getMessageDenialActionsByDate = (date: string) => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiMessageDenialAction[]>(
      `${Config().apiBaseUrl}actions/message-denials?isActioned=true&updatedDate=${date}`,
      MESSAGE_DENIAL_ACTIONS
    )
  );
};

const setFailedActions = (actionType: ActionType) => {
  let errorMessage = 'Failed to mark actions as completed';
  if (actionType === ActionType.MessageDenial) {
    errorMessage = 'Failed to mark message denials as completed';
  } else if (actionType === ActionType.RepeatUKSearch) {
    errorMessage = 'Failed to mark repeat UK search actions as completed';
  }

  return {
    type: UPDATED_ACTIONS_RESPONSE_ERROR,
    payload: {
      params: { errorMessage },
    },
  };
};

const setSuccedeedActions = (actionType: ActionType) => {
  let successMessage = 'Actions have been marked as completed';
  if (actionType === ActionType.MessageDenial) {
    successMessage = 'Message denials have been marked as completed';
  } else if (actionType === ActionType.RepeatUKSearch) {
    successMessage = 'Repeat UK search actions have been marked as completed';
  }

  return {
    type: UPDATED_ACTIONS_RESPONSE_SUCCESS,
    payload: {
      params: { successMessage },
    },
  };
};

const setActionsNotification =
  (response: ApiResponse<ApiUpdatedActionsResponse>, actionType: ActionType) => (dispatch: Dispatch<unknown>) => {
    const { body } = response.response;
    if (body) {
      if (body.FailedActions.length > 0) {
        dispatch(setFailedActions(actionType));
      } else {
        dispatch(setSuccedeedActions(actionType));
      }
    }
  };

export const updateExternalInvestigationActions =
  (actionIds: number[], actionType: ActionType) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    const url = UrlAssembler(Config().apiBaseUrl).template('actions/external-investigations/action').toString();
    const response = await dispatch(
      post(
        url,
        UPDATE_EXTERNAL_INVESTIGATION_ACTIONS,
        {
          successMessage: 'Actions have been marked as complete',
          errorMessage: 'Failed to mark actions as completed',
        },
        actionIds
      )
    );
    dispatch(setActionsNotification(response, actionType));
    return response;
  };

export const updateMessageDenialsActions =
  (actionIds: number[], actionType: ActionType) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    const url = UrlAssembler(Config().apiBaseUrl).template('actions/message-denials/action').toString();
    const response = await dispatch(
      post(
        url,
        UPDATE_MESSAGE_DENIAL_ACTIONS,
        {
          successMessage: 'Actions have been marked as complete',
          errorMessage: 'Failed to mark actions as completed',
        },
        actionIds
      )
    );
    dispatch(setActionsNotification(response, actionType));
    return response;
  };

export const getRepeatUKSearches = () => (dispatch: Dispatch<unknown>) => {
  dispatch(get<ReduxState, ApiRepeatUKSearch[]>(`${Config().apiBaseUrl}actions/repeat-uk-search`, REPEAT_UK_SEARCHES));
};

export const getRepeatUKSearchesByDate = (date: string) => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiRepeatUKSearch[]>(
      `${Config().apiBaseUrl}actions/repeat-uk-search?isActioned=true&updatedDate=${date}`,
      REPEAT_UK_SEARCHES
    )
  );
};

export const updateRepeatUKSearchActions =
  (actionIds: number[], actionType: ActionType) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    const url = UrlAssembler(Config().apiBaseUrl).template('actions/repeat-uk-search/action').toString();
    const response = await dispatch(
      post(
        url,
        UPDATE_REPEAT_UK_SEARCH_ACTIONS,
        {
          successMessage: 'Actions have been marked as complete',
          errorMessage: 'Failed to mark actions as completed',
        },
        actionIds
      )
    );
    dispatch(setActionsNotification(response, actionType));
    return response;
  };
