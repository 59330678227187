import moment, { Moment } from 'moment';
import type { ReduxState } from '../../../rootReducer';
import type { PatientSearchType, PatientSummary } from '../../types';
import type { ApiSavedReportMinimal } from '../../../searchReports/types/api';
import type { ApiSearchRequest } from '../../../donorMatchSearchRequests/types/api';
// eslint-disable-next-line import/no-cycle
import { PatientSearchReducerState } from '../../patientSearch/redux/reducer';

const DATE_FORMAT = 'YYYY-MM-DD';

const getPatients = (state: ReduxState): PatientSearchReducerState => state.searchPatients.patients;

const getSearchedPatientId = (state: ReduxState): keyof PatientSearchReducerState =>
  getPatients(state).searchedPatientId as keyof PatientSearchReducerState;

const getSearchedPatientSurname = (state: ReduxState): string | undefined => getPatients(state).searchedPatientSurname;

const getSearchedPatientDob = (state: ReduxState): Moment | undefined => getPatients(state).searchedPatientDob;

const getSearchPatient = (state: ReduxState): keyof PatientSearchReducerState =>
  getPatients(state)[getSearchedPatientId(state)] as keyof PatientSearchReducerState;

const getPatient = (state: ReduxState, id?: keyof PatientSearchReducerState) =>
  id ? getPatients(state)[id] : undefined;

const getPatientSummaries = (state: ReduxState): PatientSummary[] | undefined => getPatients(state).patientSummaries;

const getPatientSearchRequest = (
  state: ReduxState,
  patientId?: keyof PatientSearchReducerState
): ApiSearchRequest[] => {
  const patientState = getPatient(state, patientId);
  if (patientState && patientState.searchRequests) {
    return patientState.searchRequests.map(
      (requestId: string) => state.latestSearchRequests.searchRequests.requests[requestId]
    );
  }
  return [];
};

const getPatientsRequestsByDate = (state: ReduxState, id: string, date: string): ApiSearchRequest[] => {
  const patientRequests = getPatientSearchRequest(state, id);
  return patientRequests.filter((request) => moment(request.RequestedDate).isSame(date, 'day'));
};

const getDatesOfPatientRequests = (state: ReduxState, id: string): string[] => {
  const patientRequests = getPatientSearchRequest(state, id);
  const requestDates = patientRequests.reduce((dates: string[], request) => {
    if (dates.includes(moment(request.RequestedDate).format(DATE_FORMAT))) {
      return dates;
    }
    dates.push(moment(request.RequestedDate).format(DATE_FORMAT));
    return dates;
  }, []);
  return requestDates;
};

const getPatientsSavedReportsMinimal = (state: ReduxState, id: string): ApiSavedReportMinimal[] => {
  const patientState = getPatient(state, id);
  if (patientState && patientState.savedReports) {
    const results = Object.values(patientState.savedReports as Record<string, { Id: string }>)
      .map((report) => report.Id)
      .map((reportId) => state.searchReport.savedReports.reports[reportId]);
    return results;
  }
  return [];
};

const isFetchingPatient = (state: ReduxState): boolean => getPatients(state).isFetching;

const hasFetchPatientErrored = (state: ReduxState): boolean => getPatients(state).hasErrored;

const getErrorMessage = (state: ReduxState): string[] => {
  const { errorMessage } = getPatients(state);
  return errorMessage ? [errorMessage] : [];
};

const isPatientInState = (state: ReduxState, id: string): boolean => !!getPatient(state, id);

const getSearchType = (state: ReduxState): PatientSearchType => getPatients(state).searchType;

const isPatientSummariesInState = (state: ReduxState): boolean => !!getPatientSummaries(state);

const getNewPatientId = (state: ReduxState): string | null | undefined =>
  state.searchPatients.patientCreation.newPatientId;

const isPatientDetailsCompleted = (state: ReduxState): boolean =>
  state.searchPatients.patientCreation.patientDetailsCompleted;

const isPatientGeneticsCompleted = (state: ReduxState): boolean =>
  state.searchPatients.patientCreation.patientGeneticsCompleted;

const isPatientBeingSaved = (state: ReduxState): boolean => state.searchPatients.patientCreation.isSaving;

export default {
  getPatientSummaries,
  getSearchedPatientDob,
  getSearchedPatientSurname,
  getSearchedPatientId,
  getSearchPatient,
  getPatient,
  getPatientSearchRequest,
  getPatientsSavedReports: getPatientsSavedReportsMinimal,
  getPatients,
  isFetchingPatient,
  hasFetchPatientErrored,
  getErrorMessage,
  isPatientInState,
  getSearchType,
  isPatientSummariesInState,
  getDatesOfPatientRequests,
  getPatientsRequestsByDate,
  getNewPatientId,
  isPatientDetailsCompleted,
  isPatientGeneticsCompleted,
  isPatientBeingSaved,
};
