import { CSSProperties } from 'react';
import { styles as commonStyles, Styles as CommonStyles, colors } from '../../style';

export type Styles = CommonStyles & {
  searchTitle: CSSProperties;
  patientDashboardPopUp: CSSProperties;
  algorithms: CSSProperties;
};

// eslint-disable-next-line import/prefer-default-export
export const styles: Styles = {
  ...commonStyles,
  searchTitle: {
    marginBottom: '4px',
    marginTop: '16px',
  },
  patientDashboardPopUp: {
    padding: '0',
    position: 'fixed',
    top: '50%',
    left: '50%',
    // This has been set in app.scss to override react-tiny-popover style
    // transform: 'translate(-50%, -50%)',
    border: `1px solid ${colors.ANGreen}`,
    maxHeight: '90%',
    overflow: 'auto',
  },
  algorithms: {
    display: 'flex',
  },
};
