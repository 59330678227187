import React, { useEffect, useState } from 'react';
import type { Response } from '@an/nova-frontend-rest-client';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Error } from '../../../../core';
import { InfoTooltip } from '../../../../core/components/Tooltip';
import { PatientState } from '../../../../patient/types';
import { ReduxState } from '../../../../rootReducer';
import { dispatchMethods } from '../../../constants/dispatchMethods';
import { investigationTypes } from '../../../helpers/getInvestigationType';
import * as SubjectActions from '../../../redux/actions';
import SubjectSelectors from '../../../redux/selectors';
import type { DispatchMethod, SubjectType } from '../../../types';
import SampleCreationDetails from './SampleCreationDetails';
import SubjectInvestigationDetails from './SubjectInvestigationDetails';
import SubjectInvestigationInvoicing from './SubjectInvestigationInvoicing';
import * as PatientSearchActions from '../../../../patient/patientSearch/redux/actions';
import PatientSelectors from '../../../../patient/core/redux/selectors';

type Props = {
  linkedPatientId?: string | undefined;
  onClose: (investigationId: number) => void;
  subjectId: string | undefined;
  subjectRecordType: string | undefined;
  subjectType: SubjectType;
};

const CreateSubjectInvestigation = ({ linkedPatientId, onClose, subjectId, subjectRecordType, subjectType }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SubjectActions.getInstitutionAddresses());
  }, []);

  const institutionAddresses = useSelector((state: ReduxState) => SubjectSelectors.getInstitutionAddresses(state));

  useEffect(() => {
    if (linkedPatientId) {
      dispatch(PatientSearchActions.fetchPatientById(linkedPatientId));
    }
  }, [dispatch, linkedPatientId]);

  const patientIdForInvoicee = subjectType === 'donor' ? linkedPatientId : subjectId;

  const patient: PatientState = useSelector((state: ReduxState) =>
    PatientSelectors.getPatient(state, patientIdForInvoicee)
  );
  const patientInvoiceeId = patient && patient?.patientInfo.invoicee;

  const [investigationType, setInvestigationType] = useState<string>(investigationTypes.confirmatoryTyping.value);
  const [selectedCategoryRunCode, setSelectedCategoryRunCode] = useState<string | undefined>('');
  const [isUrgent, setIsUrgent] = useState(false);
  const [dispatchMethod, setDispatchMethod] = useState<DispatchMethod>(dispatchMethods.blood.id);
  const [invoiceeId, setInvoiceeId] = useState<string | undefined>(patientInvoiceeId || '');

  const createSample = bindActionCreators(SubjectActions.createSample, dispatch);

  useEffect(() => {
    if (patientInvoiceeId) {
      setInvoiceeId(patientInvoiceeId.toString());
    }
  }, [patientInvoiceeId]);

  const [requiresInvoicing, setRequiresInvoicing] = useState(true);

  const isCreatingInvestigation: boolean = useSelector(
    (state: ReduxState) => SubjectSelectors.isCreatingInvestigation(state),
    shallowEqual
  );

  const investigationError = useSelector(
    (state: ReduxState) => SubjectSelectors.getInvestigationError(state),
    shallowEqual
  );
  const invoiceAddress =
    (institutionAddresses &&
      institutionAddresses.find((address) => address.Id === parseInt(invoiceeId as string, 10))) ||
    undefined;

  const testCategories = useSelector((state: ReduxState) => SubjectSelectors.getTestCategories(state), shallowEqual);

  const isLoadingTestCategories = useSelector(
    (state: ReduxState) => SubjectSelectors.isLoadingTestCategories(state),
    shallowEqual
  );

  useEffect(() => {
    dispatch(SubjectActions.fetchTestCategories());
  }, [dispatch]);

  const testCategoriesForInvestigationType =
    testCategories && testCategories.filter((c) => c.InvestigationType === investigationType);

  const selectedCategory =
    testCategories && testCategories.find((category) => category.RunCode === selectedCategoryRunCode);

  const testsForSelectedCategory = selectedCategory ? selectedCategory.Tests : undefined;

  const [selectedTests, setSelectedTests] = useState<number[]>([]);

  useEffect(() => {
    setSelectedTests(testsForSelectedCategory ? testsForSelectedCategory.map((test) => test.TestId) : []);
  }, [testsForSelectedCategory]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // @ts-expect-error: Not recognising async action
    const sampleData: Response<any> = await createSample(subjectId, subjectType, isUrgent, dispatchMethod);
    if (sampleData && sampleData.response.ok) {
      // @ts-expect-error: Not recognising async action
      const investigationData: Response<any> = await dispatch(
        SubjectActions.createInvestigation(
          requiresInvoicing,
          subjectType,
          subjectId,
          sampleData.payload.Id,
          subjectRecordType,
          invoiceAddress,
          selectedCategoryRunCode,
          selectedTests
        )
      );
      if (investigationData.response.ok) {
        onClose(investigationData.payload.investigationId);
      }
    }
  };
  const handleInvestigationTypeChange = (type: string) => {
    setInvestigationType(type);
    setSelectedCategoryRunCode(undefined);
  };

  const selectedTestsAreValid =
    selectedTests?.length > 0 || !testsForSelectedCategory || testsForSelectedCategory?.length === 0;

  const isDisabled =
    isCreatingInvestigation ||
    !selectedCategoryRunCode ||
    !investigationType ||
    !dispatchMethod ||
    (requiresInvoicing && !invoiceeId) ||
    !selectedTestsAreValid;

  return (
    <form className="popup create-investigation-popup" onSubmit={handleSubmit}>
      <button
        aria-label="Close"
        className="close"
        data-testid="create-investigation-close-button"
        onClick={(e) => onClose(e as any)}
        type="button"
      />
      <h2 data-testid="create-investigation-header" className="header">
        {subjectRecordType} {subjectId} - Create New Investigation
      </h2>
      <SampleCreationDetails
        sampleType={dispatchMethod}
        isUrgent={isUrgent}
        onCheckboxChange={() => setIsUrgent(!isUrgent)}
        onSelectChange={(method) => {
          setDispatchMethod(method);
        }}
      />
      <SubjectInvestigationDetails
        investigationType={investigationType}
        loading={isLoadingTestCategories}
        onInvestigationTypeChange={handleInvestigationTypeChange}
        onSelectedCategoryChange={setSelectedCategoryRunCode}
        selectedCategoryRunCode={selectedCategoryRunCode}
        selectedTests={selectedTests}
        setSelectedTests={setSelectedTests}
        subjectType={subjectType}
        testCategoriesForInvestigationType={testCategoriesForInvestigationType}
        testsForSelectedCategory={testsForSelectedCategory}
      />
      <SubjectInvestigationInvoicing
        invoiceeAddresses={institutionAddresses}
        invoiceeId={invoiceeId}
        onCheckboxChange={() => setRequiresInvoicing(!requiresInvoicing)}
        onSelectChange={setInvoiceeId}
        requiresInvoicing={requiresInvoicing}
      />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          disabled={isDisabled}
          loading={isCreatingInvestigation}
          type="submit"
          onClick={() => {}}
          text="Create Investigation"
        />
        {!isLoadingTestCategories && !selectedTestsAreValid && (
          <InfoTooltip id="repeat-tooltip">At least one test must be selected.</InfoTooltip>
        )}
      </div>
      <Error error={investigationError} />
    </form>
  );
};

CreateSubjectInvestigation.defaultProps = {
  linkedPatientId: undefined,
};

export default CreateSubjectInvestigation;
