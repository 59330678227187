import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { styles } from '../../../../style';
import * as actions from '../../../../redux/actions';
import Selectors from '../../../../redux/selectors';
import type { ReduxState } from '../../../../../rootReducer';
import { DateFilter } from '../../../../types';

type StateProps = {
  // eslint-disable-next-line react/require-default-props
  dateFilter?: DateFilter;
};

type OwnProps = {
  filterName: string;
  max: string;
};

type Props = PropsFromRedux & StateProps & OwnProps;

type ChangeEvent = {
  value: string;
} & HTMLInputElement;

const mapStateToProps = (state: ReduxState): StateProps => ({
  dateFilter: Selectors.getDateFilter(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateDateFilter: bindActionCreators(actions.updateDateFilter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Filter: React.FC<Props> = ({ dateFilter, updateDateFilter, filterName, max }) => {
  const [donorAddedAfter, setDonorAddedAfter] = useState<string | undefined>(dateFilter?.value);

  useEffect(() => {
    setDonorAddedAfter(dateFilter?.value || undefined);
  }, []);

  useEffect(() => {
    if (donorAddedAfter !== dateFilter?.value) {
      updateDateFilter(donorAddedAfter as string);
    }
  }, [donorAddedAfter]);

  const handleDateFilterChange = (event: React.SyntheticEvent<ChangeEvent>) => {
    setDonorAddedAfter(event.currentTarget.value);
  };

  return (
    <label htmlFor={filterName} style={styles.inputLabel}>
      {filterName}
      <input
        id="donorAddedAfter"
        type="date"
        value={dateFilter ? donorAddedAfter || '' : ''}
        name="donorAddedAfter"
        className="Select-control"
        onChange={handleDateFilterChange}
        max={max}
      />
    </label>
  );
};

Filter.defaultProps = {
  dateFilter: undefined,
};

export default connector(Filter);
