import donorTypes from '../../core/constants/donorTypes';

import type { ReduxState } from '../../rootReducer';
import type { SavedResultSet } from '../types';
import type { DonorType } from '../../core/types';

const getUsersAndRequests = (state: ReduxState) => state.latestSearchRequests;

const getSelectedUser = (state: ReduxState): string | undefined => getUsersAndRequests(state).selectedUser;

const getSearchRequests = (state: ReduxState) => getUsersAndRequests(state).searchRequests;

const isFetchingSearchRequests = (state: ReduxState): boolean => getUsersAndRequests(state).searchRequests.isFetching;

const individualSearchRequestsBeingFetched = (state: ReduxState): string[] =>
  getUsersAndRequests(state).searchRequests.requestsBeingFetched;

const getAllSavedResultSets = (state: ReduxState, donorType: string) => {
  const searchRequests = getUsersAndRequests(state);
  return donorType === donorTypes.adult.value ? searchRequests.savedDonorSets : searchRequests.savedCordSets;
};

const getSingleSavedResultSet = (state: ReduxState, donorType: DonorType, id?: string): SavedResultSet | undefined => {
  const resultSets = getAllSavedResultSets(state, donorType);
  return id ? resultSets[id] : undefined;
};

const hasUserRequestsFetchErrored = (state: ReduxState): boolean => getSearchRequests(state).hasErrored;

const getRequestsErrorMessage = (state: ReduxState): string[] => {
  const error = getSearchRequests(state).message;
  return error ? [error] : [];
};

const isRepeatSearch = (state: ReduxState, donorSetId: string): boolean => {
  const donorSet = getAllSavedResultSets(state, donorTypes.adult.value)[donorSetId];
  if (!donorSet) return false;

  const { requestId } = donorSet;
  const request = getSearchRequests(state).requests[requestId];
  return request?.RepeatSearchInfo?.IsFirstRepeatSearch || false;
};

const areAllDonorSetsRepeatSearches = (state: ReduxState, donorSetIds: string[]): boolean => {
  if (!donorSetIds.length) return false;
  return donorSetIds.every((id) => isRepeatSearch(state, id));
};

export default {
  getAllSavedResultSets,
  getSelectedUser,
  getSingleSavedResultSet,
  getSearchRequests,
  getRequestsErrorMessage,
  hasUserRequestsFetchErrored,
  isFetchingSearchRequests,
  individualSearchRequestsBeingFetched,
  isRepeatSearch,
  areAllDonorSetsRepeatSearches,
};
