import { buildErrorAction, buildSuccessAction, buildRequestAction } from '@an/nova-frontend-rest-client';
import type { SearchType } from '../../../core/constants/searchTypes';
// eslint-disable-next-line import/no-cycle
import {
  Actions,
  GetAvailableAlgorithmsErrorAction,
  GetAvailableAlgorithmsSuccessAction,
  PatientCreateSearchRequestErrorAction,
  PatientCreateSearchRequestAlgorithmUpdateAction,
  PatientCreateSearchRequestCordEmdisRegistriesUpdateAction,
  PatientCreateSearchRequestRegistryUpdateAction,
  PatientCreateSearchRequestTypeUpdateAction,
  PatientCreateSearchRequestRequestAction,
  PatientCreateSearchRequestSuccessAction,
  PatientActivateRepeatRequestRequestAction,
  PatientActivateRepeatRequestErrorAction,
  PatientActivateRepeatRequestSuccessAction,
  PatientReactivateRepeatRequestRequestAction,
  PatientReactivateRepeatRequestErrorAction,
  PatientReactivateRepeatRequestSuccessAction,
  PatientRetryFailedSearchRequestRequestAction,
  PatientRetryFailedSearchRequestErrorAction,
  PatientRetryFailedSearchRequestSuccessAction,
  PatientRetryFailedRepeatSearchRequestRequestAction,
  PatientRetryFailedRepeatSearchRequestErrorAction,
  PatientRetryFailedRepeatSearchRequestSuccessAction,
  PatientCreateSearchRequestDpb1ScoringUpdateAction,
  PatientCreateSearchRequestIsACordAction,
  GetAvailableAlgorithmsRequestAction,
  PatientCreateSearchRequestIsHlaAdjustedAction,
  FetchPatientHlaSummaryRequestAction,
  FetchPatientHlaSummaryErrorAction,
  FetchPatientHlaSummarySuccessAction,
  GetPatientTextMessagesRequestAction,
  GetPatientTextMessagesSuccessAction,
  GetPatientTextMessagesErrorAction,
} from './actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions as PatientCreationActions,
  CreateSearchRequestAdjustedHlaAction,
} from '../../patientCreation/redux/actions';
import type { AlgorithmDataType, Genetics } from '../../types';
import { convertApiHlaSummary } from '../helpers/apiDataConverter';
import { HlaSummary, TextMessage } from '../types';

type UsersActions =
  | GetAvailableAlgorithmsErrorAction
  | GetAvailableAlgorithmsSuccessAction
  | FetchPatientHlaSummaryRequestAction
  | FetchPatientHlaSummarySuccessAction
  | FetchPatientHlaSummaryErrorAction
  | PatientCreateSearchRequestErrorAction
  | PatientCreateSearchRequestAlgorithmUpdateAction
  | PatientCreateSearchRequestCordEmdisRegistriesUpdateAction
  | PatientCreateSearchRequestRegistryUpdateAction
  | PatientCreateSearchRequestTypeUpdateAction
  | PatientCreateSearchRequestRequestAction
  | PatientCreateSearchRequestSuccessAction
  | PatientActivateRepeatRequestRequestAction
  | PatientActivateRepeatRequestErrorAction
  | PatientActivateRepeatRequestSuccessAction
  | PatientReactivateRepeatRequestRequestAction
  | PatientReactivateRepeatRequestErrorAction
  | PatientReactivateRepeatRequestSuccessAction
  | PatientRetryFailedSearchRequestRequestAction
  | PatientRetryFailedSearchRequestErrorAction
  | PatientRetryFailedSearchRequestSuccessAction
  | PatientRetryFailedRepeatSearchRequestRequestAction
  | PatientRetryFailedRepeatSearchRequestErrorAction
  | PatientRetryFailedRepeatSearchRequestSuccessAction
  | PatientCreateSearchRequestDpb1ScoringUpdateAction
  | GetAvailableAlgorithmsRequestAction
  | CreateSearchRequestAdjustedHlaAction
  | PatientCreateSearchRequestIsACordAction
  | PatientCreateSearchRequestIsHlaAdjustedAction
  | GetPatientTextMessagesRequestAction
  | GetPatientTextMessagesSuccessAction
  | GetPatientTextMessagesErrorAction;

export type PatientDashboardReducerState = {
  adjustedHlaPatientData: Record<string, Genetics>;
  availableAlgorithms: AlgorithmDataType[];
  cordEmdisRegistries: string[];
  errorMessage?: string;
  errorMessages?: string[];
  includeAlignedRegistries: boolean;
  includeDpb1InScoring: boolean;
  isACordSearch: boolean;
  isCreatingRequest: boolean;
  isHlaAdjusted: boolean;
  isLoadingAlgorithms: boolean;
  isSendingPatientToWmda: boolean;
  patientHlaIds?: Record<string, string>;
  hlaSummary?: HlaSummary;
  isHlaSummaryLoading: boolean;
  searchAlgorithms: string[];
  adultSearchType?: SearchType;
  textMessages: TextMessage[];
  isLoadingTextMessages: boolean;
  hasTextMessagesLoadingFailed: boolean;
};

const initialState: PatientDashboardReducerState = {
  adjustedHlaPatientData: {},
  availableAlgorithms: [],
  cordEmdisRegistries: [],
  includeAlignedRegistries: true,
  includeDpb1InScoring: false,
  isACordSearch: false,
  isCreatingRequest: false,
  isHlaAdjusted: false,
  isLoadingAlgorithms: false,
  isHlaSummaryLoading: false,
  isSendingPatientToWmda: false,
  patientHlaIds: undefined,
  searchAlgorithms: [],
  adultSearchType: undefined,
  textMessages: [],
  isLoadingTextMessages: false,
  hasTextMessagesLoadingFailed: false,
};

export default (
  state: PatientDashboardReducerState = initialState,
  action: UsersActions
): PatientDashboardReducerState => {
  switch (action.type) {
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_SUCCESS:
      return initialState;
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.ACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.ACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS:
      return initialState;
    case Actions.ACTIVATE_REPEAT_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.REACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.REACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS:
      return initialState;
    case Actions.REACTIVATE_REPEAT_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_SUCCESS:
      return initialState;
    case Actions.ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_SUCCESS:
      return initialState;
    case Actions.ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_TYPE_UPDATE:
      return {
        ...state,
        adultSearchType: action.payload,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_REGISTRY_UPDATE:
      return {
        ...state,
        includeAlignedRegistries: action.payload,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_DPB1_SCORING_UPDATE:
      return {
        ...state,
        includeDpb1InScoring: action.payload,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_ALGORITHM_UPDATE:
      return {
        ...state,
        searchAlgorithms: action.payload,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_CORD_EMDIS_REGISTRIES_UPDATE:
      return {
        ...state,
        cordEmdisRegistries: action.payload,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_IS_A_CORD_TOGGLE:
      return {
        ...state,
        isACordSearch: !state.isACordSearch,
      };
    case Actions.GET_AVAILABLE_ALGORITHMS_REQUEST:
      return {
        ...state,
        isLoadingAlgorithms: true,
      };
    case Actions.GET_AVAILABLE_ALGORITHMS_SUCCESS:
      return {
        ...state,
        isLoadingAlgorithms: false,
        availableAlgorithms: action.payload.data,
      };
    case Actions.FETCH_PATIENT_HLA_SUMMARY_ERROR:
      return {
        ...state,
        isHlaSummaryLoading: false,
        // @ts-expect-error - TODO: check what comes back from this endpoint, it expects an array of errorMessages which has no typing and isn't used elsewhere
        errorMessages: action.payload,
      };
    case Actions.FETCH_PATIENT_HLA_SUMMARY_REQUEST:
      return {
        ...state,
        isHlaSummaryLoading: true,
      };
    case Actions.FETCH_PATIENT_HLA_SUMMARY_SUCCESS:
      return {
        ...state,
        isHlaSummaryLoading: false,
        hlaSummary: convertApiHlaSummary(action.payload.data),
      };
    case Actions.GET_AVAILABLE_ALGORITHMS_ERROR:
      return {
        ...state,
        isLoadingAlgorithms: false,
        // @ts-expect-error - TODO: check what comes back from this endpoint, it expects an array of errorMessages which has no typing and isn't used elsewhere
        errorMessages: action.payload,
      };
    case Actions.PATIENT_CREATE_SEARCH_REQUEST_IS_HLA_ADJUSTED_TOGGLE:
      return {
        ...state,
        isHlaAdjusted: !state.isHlaAdjusted,
      };
    case PatientCreationActions.PATIENT_CREATE_SEARCH_REQUEST_ADJUSTED_HLA:
      return {
        ...state,
        adjustedHlaPatientData: {
          [action.payload.params.patientId]: action.payload.data.geneticsObject,
        },
      };
    case Actions.GET_PATIENT_TEXT_MESSAGES_REQUEST:
      return {
        ...state,
        isLoadingTextMessages: true,
        hasTextMessagesLoadingFailed: false,
      };
    case Actions.GET_PATIENT_TEXT_MESSAGES_SUCCESS:
      return {
        ...state,
        isLoadingTextMessages: false,
        hasTextMessagesLoadingFailed: false,
        textMessages: action.payload.data,
      };
    case Actions.GET_PATIENT_TEXT_MESSAGES_ERROR:
      return {
        ...state,
        isLoadingTextMessages: false,
        hasTextMessagesLoadingFailed: true,
        errorMessage: action.payload.params.errorMessage,
      };
    case buildRequestAction(Actions.SEND_PATIENT_TO_WMDA):
      return {
        ...state,
        isSendingPatientToWmda: true,
      };
    case buildSuccessAction(Actions.SEND_PATIENT_TO_WMDA):
      return {
        ...state,
        isSendingPatientToWmda: false,
      };
    case buildErrorAction(Actions.SEND_PATIENT_TO_WMDA):
      return {
        ...state,
        isSendingPatientToWmda: false,
      };
    default:
      return state;
  }
};
