const TEST_REQUESTS = 'testRequests';
const REJECTED_MESSAGES = 'rejectedMessages';
const REPEAT_UK_SEARCHES = 'repeatUKSearches';
export const tabItems = {
  testResults: {
    id: TEST_REQUESTS,
    displayName: 'Test Requests',
  },
  rejectedMessages: {
    id: REJECTED_MESSAGES,
    displayName: 'Rejected Messages',
  },
  repeatUKSearches: {
    id: REPEAT_UK_SEARCHES,
    displayName: 'Repeat UK Searches',
  },
};

export type TabItem = { id: string; displayName: string };
