import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../../patientSearch/redux/actions';
import { ResultSelectors } from '../../../../donorSearchResults/index';
import Selectors from '../../redux/selectors';
import PatientInfoRenderer from '../PatientInfoRenderer';

import type { PatientState } from '../../../types';
import type { SearchRequestSummary } from '../../../../donorMatchSearchRequests/types';
import type { ReduxState } from '../../../../rootReducer';

type OwnProps = {
  patientId?: string;
  shouldShowExtraInfoByDefault?: boolean;
};
type StateProps = {
  hasErrored: boolean;
  patient?: PatientState;
  searchRequest?: SearchRequestSummary;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  patient: Selectors.getPatient(state, ownProps.patientId),
  hasErrored: Selectors.hasFetchPatientErrored(state),
  searchRequest: ResultSelectors.getSearchRequest(state).data,
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getPatientDetails: bindActionCreators(actions.getPatientDetails, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const errorStyle: CSSProperties = {
  padding: 16,
  margin: '16px 0 16px 0',
  backgroundColor: '#F9A0A0',
};

export class DetailedPatientDisplay extends PureComponent<Props> {
  static defaultProps = {
    patient: undefined,
    patientId: undefined,
    searchRequest: undefined,
    shouldShowExtraInfoByDefault: false,
  };

  componentDidMount(): void {
    const { getPatientDetails, patientId } = this.props;
    if (patientId !== undefined) {
      getPatientDetails(patientId);
    }
  }

  render(): ReactNode {
    const { hasErrored, patient, searchRequest, shouldShowExtraInfoByDefault } = this.props;
    const patientDisplay = (
      <PatientInfoRenderer
        patient={patient ? patient.patientInfo : undefined}
        searchedHla={searchRequest}
        isExpandedByDefault={shouldShowExtraInfoByDefault}
      />
    );

    return (
      <div id="patient-info" className="col span_12_of_12">
        {hasErrored ? this.errorMessage() : patientDisplay}
      </div>
    );
  }

  errorMessage = (): JSX.Element => (
    <div style={errorStyle}>
      An error has occurred while fetching the requested patient data. Please refresh the page to try again.
    </div>
  );
}

export default connector(DetailedPatientDisplay);
