import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouterMatch } from '../../../../core/types';
import { FeatureFlag } from '../../../../featureFlags';
import SubjectLinks from '../../../../subject/components/Links/SubjectLinks';
import SubjectInvestigationsScreen from '../../../../subject/components/SubjectInvestigations/SubjectInvestigationsScreen';
import { TabItem, tabItems } from '../../constants/tabItems';
import EmdisRequestsTab from './EmdisRequests/EmdisRequestsTab';
import ExternalInvestigationsTab from './ExternalInvestigations/ExternalInvestigationsTab';
import SavedReportsTab from './SavedReports/SavedReportsTab';
import SearchRequestsTab from './SearchRequests/SearchRequestsTab';
import Selectors from '../../../core/redux/selectors';
import { ReduxState } from '../../../../rootReducer';
import { PatientState } from '../../../types';
import TextMessagesTab from './TextMessages/TextMessagesTab';

type Props = {
  patientId: string;
  match: RouterMatch;
  isPatientClosed: boolean | null | undefined;
};
const ResultsTabs = ({ match, patientId, isPatientClosed }: Props) => {
  const { activeTab } = match.params;

  const patient: PatientState = useSelector((state: ReduxState) => Selectors.getPatient(state, patientId));

  const renderTab = (item: TabItem) => (
    <li key={item.id}>
      <Link className={activeTab === item.id ? 'active' : ''} to={`/patient/${patientId}/${item.id}`}>
        {item.displayName}{' '}
      </Link>{' '}
    </li>
  );

  const applyFeatureFlag = (item: TabItem) => {
    if (item.id === tabItems.testRequests.id) {
      return <FeatureFlag flag="showTestRequestsTab" key={item.id} render={() => renderTab(item)} />;
    }
    if (item.id === tabItems.textMessages.id) {
      return renderTab(item);
    }
    if (item.id === tabItems.investigations.id) {
      return renderTab(item);
    }
    if (item.id === tabItems.linkedDonors.id) {
      return renderTab(item);
    }
    return renderTab(item);
  };

  const tabs = Object.values(tabItems).map((item: TabItem) => applyFeatureFlag(item));

  return (
    <div className="content group">
      <main role="main" className="col span_12_of_12">
        <ul className="nav-tabs">{tabs}</ul>
        {activeTab === tabItems.ukSearchRequests.id && (
          <SearchRequestsTab patientId={patientId} isPatientClosed={isPatientClosed} />
        )}
        {activeTab === tabItems.emdisSearchRequests.id && <EmdisRequestsTab patientId={patientId} />}
        {activeTab === tabItems.searchReports.id && <SavedReportsTab patientId={patientId} />}
        {activeTab === tabItems.testRequests.id && <ExternalInvestigationsTab patientId={patientId} />}
        {activeTab === tabItems.textMessages.id && <TextMessagesTab patientId={patientId} />}
        {activeTab === tabItems.investigations.id && <SubjectInvestigationsScreen subject={patient?.patientInfo} />}
        {activeTab === tabItems.linkedDonors.id && <SubjectLinks subjectId={patientId} subjectType="patient" />}
      </main>
    </div>
  );
};

export default ResultsTabs;
