import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import type { Features } from './featureFlags';

// @ts-expect-error no-underscore-dangle
// eslint-disable-next-line no-underscore-dangle
const env = window._env_;
const config = {
  accessTokenKey: 'novaApiAccessToken',
  apiBaseUrl: env.REACT_APP_API_BASE || 'https://dev-nova-search-api.azurewebsites.net/api/',
  authority: env.REACT_APP_MSAL_AUTHORITY || 'https://login.microsoftonline.com/6f7ab052-989d-4dd8-b283-317f0a9b7b2c',
  authScope: env.REACT_APP_MSAL_AUTH_SCOPE || 'api://dev-nova-search-api/Access.Portal',
  clientBaseUrl: env.REACT_APP_CLIENT_BASE_URL || 'http://localhost:3000',
  clientId: env.REACT_APP_AD_CLIENT_ID || 'ae539fe3-b6d3-43d0-9ea4-a670f4827fb6',
  // The max length for a displayed hla string on the hla editing page before truncation occurs
  defaultHlaEditingStringMaxLength: 100,
  reportDownloadAttempts: env.REPORT_DOWNLOAD_ATTEMPTS || 20,
  reportDownloadWaitBetweenAttemptsSec: env.REPORT_DOWNLOAD_WAIT_BETWEEN_ATTEMPTS_SEC || 5,
};

// eslint-disable-next-line func-names
export default function () {
  return config;
}

const defaultFeatures: Features = {
  dpb1Filtering: false,
  repeatSearchEnabled: false,
  showAdjustHLAButton: false,
  showCancelTestRequestsFeature: false,
  showEmdisSearchResults: true,
  showExtendedTypingRequestButton: false,
  showHlaReportButton: false,
  showIdmRequestButton: false,
  showNewEMDISSearchRequestButton: false,
  showOptionToIncludeDPB1InDonorRanking: false,
  showTestRequestsTab: false,
  showUnauthoriseButton: false,
  showVerificationTypingRequestButton: false,
  showVirologyReportButton: false,
  tcManagementRepeatUkSearches: false,
};

export const featureFlags = {
  useLocalFlags: false,
  // These default feature flags are used if useLocalFlags above is set to true,
  // or as a fallback if the feature flag endpoint fails.
  defaultFeatures,
};

const msalConfig: Configuration = {
  auth: {
    clientId: config.clientId,
    authority: config.authority,
    knownAuthorities: [config.authority],
    redirectUri: config.clientBaseUrl,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const authScopes = { scopes: [config.authScope] };
