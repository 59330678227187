import moment, { Moment } from 'moment';
import type { ReduxState } from '../../../rootReducer';
import type { AlgorithmDataType, Institution } from '../../types';
import type { VerificationTypingResult, HlaSummary, InternationalSearchCreationConstants, TextMessage } from '../types';

const getSearchAlgorithms = (state: ReduxState): string[] =>
  state.searchPatients.searchRequestInitiation.searchAlgorithms;

const getSearchRequestData = (state: ReduxState) => state.searchPatients.searchRequestInitiation;

const getAvailableAlgorithms = (state: ReduxState): AlgorithmDataType[] =>
  state.searchPatients.searchRequestInitiation.availableAlgorithms;

const isLoadingAlgorithms = (state: ReduxState): boolean =>
  state.searchPatients.searchRequestInitiation.isLoadingAlgorithms;

const getAlgorithmErrors = (state: ReduxState): string[] =>
  state.searchPatients.searchRequestInitiation.errorMessages || [];

const getExternalRegistries = (state: ReduxState): Institution[] =>
  state.searchPatients.patientCreation.externalRegistries;

const getPatientDetails = (state: ReduxState, patientId: string) =>
  state.searchPatients.patients[patientId].patientInfo;

const getPatientStatus = (state: ReduxState, patientId: string) =>
  state.searchPatients.patients[patientId].patientInfo.status;

const getPatientRegisteredDate = (state: ReduxState, patientId: string) =>
  state.searchPatients.patients[patientId].patientInfo.dateRegistered;

const getEmdisIsCreating = (state: ReduxState): boolean =>
  state.searchPatients.emdisSearchRequestInitiation.isCreatingRequest;

const getInternationalSearchRequestCreationConstants = (
  state: ReduxState
): InternationalSearchCreationConstants | undefined =>
  state.searchPatients.emdisSearchRequestInitiation.internationalSearchCreationConstants;

const getRepeatSearchDate = (state: ReduxState, patientId?: string): Moment | undefined =>
  patientId && state.searchPatients.patients[patientId]?.repeatSearchDate
    ? moment(state.searchPatients.patients[patientId].repeatSearchDate)
    : undefined;

const getIsSendingPatientToWmda = (state: ReduxState): boolean =>
  state.searchPatients.searchRequestInitiation.isSendingPatientToWmda;

const getIsPatientClosed = (state: ReduxState, patientId?: string): boolean | undefined =>
  patientId && state.searchPatients.patients[patientId]
    ? state.searchPatients.patients[patientId].patientInfo?.status === 'Closed'
    : undefined;

const getPatientClosureReasons = (state: ReduxState): Array<string> | null | undefined =>
  state.searchPatients.patientStatus.patientClosureReasons;

const getIsLoadingPatientClosureReasons = (state: ReduxState): boolean =>
  state.searchPatients.patientStatus.isFetchingClosureReasons;

const getFetchPatientClosureReasonErrors = (state: ReduxState): string | null | undefined =>
  state.searchPatients.patientStatus.fetchClosureReasonsError;

const getIsClosingPatient = (state: ReduxState): boolean => state.searchPatients.patientStatus.isClosingPatient;

const getClosePatientError = (state: ReduxState): string | null | undefined =>
  state.searchPatients.patientStatus.closePatientError;

const getIsOpeningPatient = (state: ReduxState): boolean => state.searchPatients.patientStatus.isOpeningPatient;

const getOpenPatientError = (state: ReduxState): string | null | undefined =>
  state.searchPatients.patientStatus.openPatientError;

const getHlaSummary = (state: ReduxState): undefined | HlaSummary =>
  state.searchPatients.searchRequestInitiation.hlaSummary;

const isHlaSummaryLoading = (state: ReduxState): boolean =>
  state.searchPatients.searchRequestInitiation.isHlaSummaryLoading;

const getHlaResult = (state: ReduxState): undefined | VerificationTypingResult =>
  state.externalInvestigations.verificationTypingResult;

const isVerificationTypingResultLoading = (state: ReduxState): boolean =>
  state.externalInvestigations.isVerificationTypingResultLoading;

const getPatientTextMessages = (state: ReduxState): TextMessage[] =>
  state.searchPatients.searchRequestInitiation.textMessages;

const isLoadingTextMessages = (state: ReduxState): boolean =>
  state.searchPatients.searchRequestInitiation.isLoadingTextMessages;

const hasTextMessagesLoadingFailed = (state: ReduxState): boolean =>
  state.searchPatients.searchRequestInitiation.hasTextMessagesLoadingFailed;

const getTextMessagesErrorMessage = (state: ReduxState): string[] => {
  const error = state.searchPatients.searchRequestInitiation.errorMessage;
  return error ? [error] : [];
};

export default {
  getAlgorithmErrors,
  getExternalRegistries,
  getEmdisIsCreating,
  getIsSendingPatientToWmda,
  getPatientDetails,
  getPatientStatus,
  getPatientRegisteredDate,
  getSearchAlgorithms,
  getSearchRequestData,
  getAvailableAlgorithms,
  isLoadingAlgorithms,
  getInternationalSearchRequestCreationConstants,
  getRepeatSearchDate,
  getIsPatientClosed,
  getPatientClosureReasons,
  getIsLoadingPatientClosureReasons,
  getFetchPatientClosureReasonErrors,
  getIsClosingPatient,
  getClosePatientError,
  getIsOpeningPatient,
  getOpenPatientError,
  getHlaSummary,
  isHlaSummaryLoading,
  getHlaResult,
  isVerificationTypingResultLoading,
  getPatientTextMessages,
  isLoadingTextMessages,
  hasTextMessagesLoadingFailed,
  getTextMessagesErrorMessage,
};
