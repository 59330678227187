import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import type { ReduxState } from '../../../rootReducer';
import type { DonorType } from '../../../core/types';
import { SearchRequestSelectors } from '../../../donorMatchSearchRequests';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import { PatientSelectors } from '../../../patient';
import ReportType from './ReportType';
import TransplantCentre from './TransplantCentre';
import ReportRecommendations from './ReportRecommendations';
import PrivateNotes from './PrivateNotes';
import InternalRequestsList from './InternalRequestsList';
import InternationalRequestsList from './InternationalRequestsList';
import CountriesTable from './International/CountriesTable';
import { SelectedDonorSetsTable } from './International/SelectedDonorSetsTable';
import { RecentSearchSummary } from './RecentSearchSummary';
import { donorTypes, reportTypes, algorithmTypes, LoadingMessage } from '../../../core';
import type { ApiSearchRequest, ApiSearchRequestSummary } from '../../../donorMatchSearchRequests/types/api';

import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';
import { ReceivedDate } from './ReceivedDate';

const internalTabItems = [
  { id: 1, name: 'Saved Adult Sets' },
  { id: 2, name: 'Saved Cord Sets' },
  { id: 3, name: 'Details' },
];

const internationalTabItems = [
  { id: 1, name: 'International Donors' },
  { id: 2, name: 'Details' },
];

type OwnProps = {
  patientId?: string;
  reportType: string;
  isNewReport: boolean;
};
type StateProps = {
  isFetchingSearchRequests: boolean;
  selectedDonorSets: SavedResultSet[];
  patientSearchRequests?: ApiSearchRequest[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;
type State = {
  activeTab: number | null | undefined;
};
type TabProps = {
  patientId?: string;
  isFetching: boolean;
  selectedDonorSets: SavedResultSet[];
};
type InternalDetailsTabProps = TabProps & {
  recentSearch: ApiSearchRequestSummary;
  patientSearchRequests?: ApiSearchRequest[];
  internal: string;
};

const DetailsTab = ({
  patientId,
  isFetching,
  selectedDonorSets,
  recentSearch,
  patientSearchRequests,
  internal,
}: InternalDetailsTabProps) => (
  <div className="results-table results-table--border" style={{ display: 'flex' }}>
    <div className="col span_6_of_12">
      <ReportRecommendations />
    </div>
    <div className="col span_6_of_12">
      <ReceivedDate />
      <div style={{ marginTop: '20px' }}>
        <ReportType isInternationalReport={false} patientId={patientId} selectedDonorSets={selectedDonorSets} />
      </div>
      <div style={{ marginTop: '20px' }}>
        <TransplantCentre />
      </div>
      <PrivateNotes />
      <div style={{ margin: '20px 0' }}>
        <LoadingMessage isLoading={isFetching}>
          <div style={{ flex: '100%', fontSize: '1.7rem' }}>
            <RecentSearchSummary recentSearch={recentSearch} />
            {!_.isEmpty(selectedDonorSets) && (
              <SelectedDonorSetsTable
                selectedDonorSets={selectedDonorSets}
                patientSearchRequests={patientSearchRequests}
                isInternalSearch={internal}
              />
            )}
          </div>
        </LoadingMessage>
      </div>
    </div>
  </div>
);
DetailsTab.defaultProps = {
  patientId: undefined,
  patientSearchRequests: undefined,
};

const InternationalDetailsTab = ({ isFetching, patientId, selectedDonorSets }: TabProps) => (
  <div>
    <h2 className="border-bottom-solid">International Report</h2>
    <div style={{ display: 'flex', margin: '40px 0' }}>
      <div style={{ flex: '50%', marginRight: '40px' }}>
        {!isFetching ? <CountriesTable /> : <LoadingMessage />}
        {!isFetching && !_.isEmpty(selectedDonorSets) && (
          <SelectedDonorSetsTable selectedDonorSets={selectedDonorSets} />
        )}
      </div>
      <div style={{ flex: '50%' }}>
        <ReceivedDate />
        <div style={{ marginTop: '20px' }}>
          <ReportType isInternationalReport patientId={patientId} />
        </div>
        <TransplantCentre />
        <PrivateNotes />
      </div>
    </div>
  </div>
);
InternationalDetailsTab.defaultProps = {
  patientId: undefined,
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  isFetchingSearchRequests: SearchRequestSelectors.isFetchingSearchRequests(state),
  selectedDonorSets: CurrentReportSelectors.getSelectedDonorSets(state),

  patientSearchRequests: PatientSelectors.getPatientSearchRequest(state, ownProps.patientId),
});
export class ResultsTabs extends React.Component<Props, State> {
  static defaultProps = {
    patientId: undefined,
    patientSearchRequests: undefined,
  };

  state = {
    activeTab: null,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const defaultTab = this.getDefaultTab();
    this.setState({ activeTab: defaultTab });
  }

  render() {
    const { activeTab } = this.state;
    const { patientId, reportType, isFetchingSearchRequests, selectedDonorSets, patientSearchRequests } = this.props;
    const tabItems = reportType === reportTypes.international ? internationalTabItems : internalTabItems;

    const tabs = tabItems.map((item) => (
      <li key={item.id}>
        <a
          href={`${item.id}`}
          className={activeTab === item.id ? 'active' : ''}
          onClick={(e) => this.onTabChange(e, item.id)}
        >
          {item.name}
        </a>
      </li>
    ));

    const tenOutOfTenSearches = _.filter(patientSearchRequests, {
      AdultSearchType: 'TenOutOfTen',
    });

    const tenOutOfTenNovaSearches = tenOutOfTenSearches.filter((search) =>
      search.ResultSetSummaries.find(
        (summary) =>
          // While an independent algorithm type in the backend, Atlas search is known as "AN search v2" to users, and should be included in recent 10/10 searches
          (summary.AlgorithmUsed === algorithmTypes.novaControl || summary.AlgorithmUsed === algorithmTypes.atlas) &&
          summary.DonorType === donorTypes.adult.value
      )
    );

    const orderedTenOutOfTenSearches = _.orderBy(tenOutOfTenNovaSearches, 'RequestedDate', 'desc');
    const mostRecentTenOutOfTenSearch = orderedTenOutOfTenSearches[0];

    return (
      <div className="group">
        <main role="main" className="col span_12_of_12">
          <ul className="nav-tabs">{tabs}</ul>
          {activeTab === tabItems[0].id && (
            <div className="results-table results-table--border">
              <h2 className="border-bottom-solid">{tabItems[0].name}</h2>
              {reportType === reportTypes.internal ? (
                <InternalRequestsList
                  patientId={patientId}
                  donorType={donorTypes.adult.value as DonorType}
                  reportType={reportType}
                />
              ) : (
                <InternationalRequestsList
                  patientId={patientId}
                  donorType={donorTypes.adult.value as DonorType}
                  reportType={reportType}
                />
              )}
            </div>
          )}
          {activeTab === tabItems[1].id && (
            <div className="results-table results-table--border">
              {reportType === reportTypes.internal ? (
                <div>
                  <h2 className="border-bottom-solid">{tabItems[1].name}</h2>
                  <InternalRequestsList
                    patientId={patientId}
                    donorType={donorTypes.cord.value as DonorType}
                    reportType={reportType}
                  />
                </div>
              ) : (
                <InternationalDetailsTab
                  isFetching={isFetchingSearchRequests}
                  patientId={patientId}
                  selectedDonorSets={selectedDonorSets}
                />
              )}
            </div>
          )}
          {tabItems[2] && activeTab === tabItems[2].id && (
            <div className="results-table results-table--border">
              <DetailsTab
                recentSearch={mostRecentTenOutOfTenSearch}
                isFetching={isFetchingSearchRequests}
                patientId={patientId}
                selectedDonorSets={selectedDonorSets}
                patientSearchRequests={patientSearchRequests}
                internal={reportTypes.internal}
              />
            </div>
          )}
        </main>
      </div>
    );
  }

  onTabChange = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    this.setState({
      activeTab: id,
    });
  };

  getDefaultTab = () => {
    const { reportType, isNewReport } = this.props;
    if (isNewReport) {
      return 1;
    }
    return reportType === reportTypes.international ? 2 : 3;
  };
}

const connector = connect(mapStateToProps);

export default connector(ResultsTabs);
