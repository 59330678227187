import UrlAssembler from 'url-assembler';
import _ from 'lodash';
import { get, ErrorAction as ApiErrorAction, SuccessAction as ApiSuccessAction } from '@an/nova-frontend-rest-client';
import { Moment } from 'moment';
// eslint-disable-next-line import/no-cycle
import Selectors from '../../core/redux/selectors';
import Config from '../../../config';
import type { Dispatch, GetState } from '../../../core/types';
import type { PatientSearchReducerState } from './reducer';
import type { ApiPatientInfo } from '../types/api';
import type { PatientSearchType, PatientSummary } from '../../types';

const PATIENT_FETCH: 'patient/fetch' = 'patient/fetch';
const PATIENT_FETCH_ERROR: 'patient/fetch/error' = 'patient/fetch/error';
const PATIENT_FETCH_REQUEST: 'patient/fetch/request' = 'patient/fetch/request';
const PATIENT_FETCH_SUCCESS: 'patient/fetch/success' = 'patient/fetch/success';
const PATIENT_SUMMARIES_FETCH: 'patient/summaries/fetch' = 'patient/summaries/fetch';
const PATIENT_SUMMARIES_FETCH_ERROR: 'patient/summaries/fetch/error' = 'patient/summaries/fetch/error';
const PATIENT_SUMMARIES_FETCH_REQUEST: 'patient/summaries/fetch/request' = 'patient/summaries/fetch/request';
const PATIENT_SUMMARIES_FETCH_SUCCESS: 'patient/summaries/fetch/success' = 'patient/summaries/fetch/success';
const PATIENT_RESET_ERROR: 'patient/error/reset' = 'patient/error/reset';
const PATIENT_RESET_SUMMARIES: 'patient/summaries/reset' = 'patient/summaries/reset';
const PATIENT_SET_SEARCHED_PATIENT_DOB: 'patient/patientDob/set' = 'patient/patientDob/set';
const PATIENT_SET_SEARCHED_PATIENT_ID: 'patient/patientId/set' = 'patient/patientId/set';
const PATIENT_SET_SEARCHED_PATIENT_SURNAME: 'patient/patientSurname/set' = 'patient/patientSurname/set';
const PATIENT_UPDATE_SEARCH_TYPE: 'patient/searchType/update' = 'patient/searchType/update';

export const Actions = {
  PATIENT_FETCH_ERROR,
  PATIENT_FETCH_REQUEST,
  PATIENT_FETCH_SUCCESS,
  PATIENT_RESET_ERROR,
  PATIENT_RESET_SUMMARIES,
  PATIENT_SET_SEARCHED_PATIENT_DOB,
  PATIENT_SET_SEARCHED_PATIENT_ID,
  PATIENT_SET_SEARCHED_PATIENT_SURNAME,
  PATIENT_SUMMARIES_FETCH_ERROR,
  PATIENT_SUMMARIES_FETCH_REQUEST,
  PATIENT_SUMMARIES_FETCH_SUCCESS,
  PATIENT_UPDATE_SEARCH_TYPE,
};

type PatientIdParam = { patientId: string };
type SearchFiltersParam = { searchFilters: { [string: string]: string | null | undefined } };

export type PatientFetchRequestAction = { type: typeof PATIENT_FETCH_REQUEST };
export type PatientFetchErrorAction = ApiErrorAction<typeof PATIENT_FETCH_ERROR, PatientIdParam>;
export type PatientFetchSuccessAction = ApiSuccessAction<typeof PATIENT_FETCH_SUCCESS, PatientIdParam, ApiPatientInfo>;
export type PatientSummariesFetchRequestAction = { type: typeof PATIENT_SUMMARIES_FETCH_REQUEST };
export type PatientSummariesFetchErrorAction = ApiErrorAction<typeof PATIENT_SUMMARIES_FETCH_ERROR, SearchFiltersParam>;
export type PatientSummariesFetchSuccessAction = ApiSuccessAction<
  typeof PATIENT_SUMMARIES_FETCH_SUCCESS,
  SearchFiltersParam,
  PatientSummary[]
>;
export type PatientUpdateSearchTypeAction = {
  type: typeof PATIENT_UPDATE_SEARCH_TYPE;
  payload: PatientSearchType;
};
export type SetSearchPatientDobAction = {
  type: typeof PATIENT_SET_SEARCHED_PATIENT_DOB;
  payload?: Moment;
};
export type SetSearchPatientIdAction = {
  type: typeof PATIENT_SET_SEARCHED_PATIENT_ID;
  payload: string;
};
export type SetSearchPatientSurnameAction = {
  type: typeof PATIENT_SET_SEARCHED_PATIENT_SURNAME;
  payload?: string;
};
export type PatientResetSummariesAction = {
  type: typeof PATIENT_RESET_SUMMARIES;
};
export type PatientResetErrorAction = {
  type: typeof PATIENT_RESET_ERROR;
};

const setSearchPatientDob = (patientDob?: Moment): SetSearchPatientDobAction => ({
  type: PATIENT_SET_SEARCHED_PATIENT_DOB,
  payload: patientDob,
});

export const setSearchPatientId = (patientId: string): SetSearchPatientIdAction => ({
  type: PATIENT_SET_SEARCHED_PATIENT_ID,
  payload: patientId,
});

const setSearchPatientSurname = (patientSurname?: string): SetSearchPatientSurnameAction => ({
  type: PATIENT_SET_SEARCHED_PATIENT_SURNAME,
  payload: patientSurname,
});

export const updateSearchType = (searchType: PatientSearchType): PatientUpdateSearchTypeAction => ({
  type: PATIENT_UPDATE_SEARCH_TYPE,
  payload: searchType,
});

export const resetPatientSummaries = () => ({
  type: PATIENT_RESET_SUMMARIES,
});

const resetError = () => ({
  type: PATIENT_RESET_ERROR,
});

export const fetchPatientById = (patientId: string) => (dispatch: Dispatch<any>) => {
  dispatch(
    get(`${Config().apiBaseUrl}patients/details/${patientId}`, PATIENT_FETCH, {
      patientId,
    })
  );
};

const shouldFetchPatient = (patientId: string, patientState: PatientSearchReducerState) => !patientState[patientId];

export const getPatientDetails = (patientId: string) => (dispatch: Dispatch<any>, getState: GetState) => {
  dispatch(setSearchPatientId(patientId));
  if (shouldFetchPatient(patientId, Selectors.getPatients(getState()))) {
    dispatch(fetchPatientById(patientId));
  } else {
    dispatch(resetError());
  }
};

const getQueryParameters = (searchParams: any) => _.pickBy(searchParams, _.identity);

const fetchPatientByNameAndDob =
  (searchFilters: { [string: string]: string | null | undefined }) => async (dispatch: Dispatch<any>) => {
    const patientSearchByNameDobUrl = UrlAssembler(Config().apiBaseUrl)
      .template('patients')
      .query(getQueryParameters(searchFilters))
      .toString();

    await dispatch(
      get(patientSearchByNameDobUrl, PATIENT_SUMMARIES_FETCH, {
        searchFilters,
      })
    );
  };

export const getPatientDetailsByNameAndDob =
  (patientSurname?: string, patientDob?: Moment) => async (dispatch: Dispatch<any>, getState: GetState) => {
    dispatch(setSearchPatientSurname(patientSurname));
    dispatch(setSearchPatientDob(patientDob));

    const patientSearchFilters = {
      'patientSearchModel.Surname': patientSurname,
      'patientSearchModel.DateOfBirth': patientDob ? patientDob.format('DD-MMM-YYYY') : null,
    };

    await dispatch(fetchPatientByNameAndDob(patientSearchFilters));
    const patientSummaries = Selectors.getPatientSummaries(getState());
    if (patientSummaries) {
      patientSummaries.forEach((patientSummary) => dispatch(getPatientDetails(patientSummary.Id)));
    }
  };
