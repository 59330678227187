import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MdChevronRight from 'react-icons/lib/md/chevron-right';
import ExpandMore from 'react-icons/lib/md/expand-more';
import { LoadingMessage } from '../../../core';
import { formatDate } from '../../../core/helpers/dateHelpers';
import { RepeatUKSearch } from '../../types';
import RepeatUKSearchEventDetailsTable from './RepeatUKSearchEventDetailsTable';
import { convertSearchTypeToNumeric } from '../../../core/constants/searchTypes';
import TransplantCentreFilterToolBar from '../Common/TransplantCentreFilterToolBar';
import { TransplantCentreTabType } from '../../constants/TransplantCentreTabTypes';

type Props = {
  repeatUKSearches: RepeatUKSearch[];
  isLoading: boolean;
};

export const RepeatUKSearchesTable = ({ repeatUKSearches, isLoading }: Props) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [tableDataRows, setTableDataRows] = useState<(RepeatUKSearch | null)[]>(repeatUKSearches);
  const [filteredRepeatUKSearchesTableData, setFilteredRepeatUKSearchesTableData] =
    useState<(RepeatUKSearch | null)[]>(repeatUKSearches);

  useEffect(() => {
    setTableDataRows(repeatUKSearches);
    setFilteredRepeatUKSearchesTableData(repeatUKSearches);
  }, [repeatUKSearches]);

  const getEarliestEventDate = (search: RepeatUKSearch): Date =>
    search.actions.reduce((earliest, event) => {
      const eventDate = new Date(event.eventDate);
      return eventDate < earliest ? eventDate : earliest;
    }, new Date(search.actions[0]?.eventDate || Date.now()));

  const handleRowClick = (searchId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(searchId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== searchId)
      : currentExpandedRows.concat(searchId);
    setExpandedRows(newExpandedRows);
  };

  const isExpanded = (search: RepeatUKSearch) => expandedRows.includes(Number(search.firstRepeatSearchRequestId));

  const renderItem = (search: RepeatUKSearch): JSX.Element[] => {
    const rows = [
      <tr
        key={search.firstRepeatSearchRequestId}
        className={`centered-table-data${isExpanded(search) ? ' expanded' : ''}`}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <td
          className="chevron"
          onClick={() => handleRowClick(Number(search.firstRepeatSearchRequestId))}
          style={{ cursor: 'pointer' }}
        >
          {isExpanded(search) ? <ExpandMore size="35" /> : <MdChevronRight size="35" />}
          {search.transplantCentreName}
        </td>
        <td className="repeat-uk-searches__patientId">{search.formattedPatientId}</td>
        <td>{convertSearchTypeToNumeric(search.searchType)}</td>
        <td>{search.donorType}</td>
        <td>{formatDate(search.firstRunDate)}</td>
        <td>{search.runBy}</td>
        <td>{search.repeatSearchStatus}</td>
        <td className="table-button-cell">
          <Link
            to={`../patient/${search.patientId}/ukSearchRequests/${search.firstRepeatSearchRequestId}`}
            target="_blank"
          >
            View Search Request
          </Link>
        </td>
      </tr>,
    ];

    if (isExpanded(search)) {
      rows.push(
        <tr key={`eventDetails${search.firstRepeatSearchRequestId}`}>
          <td colSpan={8} className="expanded-row">
            <RepeatUKSearchEventDetailsTable
              events={search.actions}
              searchId={search.firstRepeatSearchRequestId}
              repeatSearches={tableDataRows}
              setTableDataRows={setTableDataRows}
            />
          </td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <>
      <TransplantCentreFilterToolBar
        tableDataRows={tableDataRows}
        setFilteredRepeatSearchTableData={setFilteredRepeatUKSearchesTableData}
        setExpandedRows={setExpandedRows}
        settings={{ type: false, grid: false }}
        transplantCentreTabType={TransplantCentreTabType.RepeatUKSearches}
      />
      <LoadingMessage isLoading={isLoading}>
        <div className="table-wrapper" style={{ maxHeight: '54vh' }}>
          <table className="table">
            <thead className="table-header table--sticky-header">
              <tr>
                <th>TC</th>
                <th className="repeat-uk-searches__patientId">Patient ID</th>
                <th>Search Type</th>
                <th>Donor Type</th>
                <th>First Run Date</th>
                <th>Run By</th>
                <th>Status</th>
                <th>View Search</th>
              </tr>
            </thead>
            <tbody>
              {filteredRepeatUKSearchesTableData && filteredRepeatUKSearchesTableData.length > 0 ? (
                filteredRepeatUKSearchesTableData
                  .filter((search): search is RepeatUKSearch => search !== null)
                  .sort((a, b) => {
                    const dateA = getEarliestEventDate(a);
                    const dateB = getEarliestEventDate(b);
                    return dateA.getTime() - dateB.getTime();
                  })
                  .flatMap((search) => renderItem(search))
              ) : (
                <tr>
                  <td colSpan={8} style={{ textAlign: 'center', padding: '20px' }}>
                    No updates for Repeat UK Searches found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </LoadingMessage>
    </>
  );
};

export default RepeatUKSearchesTable;
