import log from 'loglevel';
import type { AppliedFiltersArray, Filter } from '../types';
import type { AdultDonor } from '../../core/types';
import { formatDate } from '../../core/helpers/dateHelpers';
import { filterNames } from '../constants/filterNames';
import { getLocusMatchCategoryDisplayValue } from '../constants/locusMatchCategories';

const getRegistryNames = (registryIds: string[], adultDonors: AdultDonor[]) =>
  registryIds.map((registryId) => {
    const donor = adultDonors.find((d) => d.originatingRegistry.id === registryId);
    return donor ? donor.originatingRegistry.name : '';
  });

const getAppliedFiltersAsString = (appliedFilters: AppliedFiltersArray, adultDonors?: AdultDonor[]): string => {
  const reducer = (accumulator: string, filter: Filter) => {
    let nextValue = accumulator;

    if (filter?.name === filterNames.abo) {
      if (filter?.value.length > 0) {
        const values = filter?.value.map((value) => `ABO-${value}`);
        return `${accumulator}, ${values.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.age) {
      if (filter?.value.minAge) {
        nextValue += `, Min Age: ${filter?.value.minAge}`;
      }
      if (filter?.value.maxAge) {
        nextValue += `, Max Age: ${filter?.value.maxAge}`;
      }
      return nextValue;
    }
    if (filter?.name === filterNames.cd34) {
      if (filter?.value.min) {
        nextValue += `, Min CD34: ${filter?.value.min}`;
      }
      return nextValue;
    }
    if (filter?.name === filterNames.cmv) {
      if (filter?.value.length > 0) {
        const values = filter?.value.map((value) => `CMV-${value}`);
        return `${accumulator}, ${values.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.dpb1MatchCategories) {
      if (filter?.value.length > 0) {
        const formattedMatchCategories = filter?.value.map(
          (category) => `DPB1-${getLocusMatchCategoryDisplayValue(category)}`
        );
        return `${accumulator}, ${formattedMatchCategories.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.excludeDkmsUkDonors) {
      return `${accumulator}, Exclude-DKMS-UK-Donors-${filter?.value.toString()}`;
    }
    if (filter?.name === filterNames.gender) {
      if (filter?.value.length > 0) {
        return `${accumulator}, ${filter?.value.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.grid) {
      if (filter?.value.length > 0) {
        const values = filter?.value.map((value) => `GRID-${value}`);
        return `${accumulator}, ${values.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.homeRegistryId) {
      if (filter?.value.length > 0) {
        const values = filter?.value.map((value) => `DonorId-${value}`);
        return `${accumulator}, ${values.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.mismatches) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { locus, max } = filter?.value;
      if (locus && max != null) {
        return `${accumulator},  Mismatches-HLA-${locus.toUpperCase()}: ${max} ${max > 1 ? 'mismatches' : 'mismatch'}`;
      }
      return `${accumulator},  Mismatches-None`;
    }
    if (filter?.name === filterNames.originatingRegistryId) {
      if (adultDonors) {
        const registryNames = getRegistryNames(filter?.value, adultDonors);
        const formattedNames = registryNames.map((reg) => `Registry-${reg}`);
        return `${accumulator}, ${formattedNames.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.rhd) {
      if (filter?.value.length > 0) {
        const values = filter?.value.map((value) => `RhD-${value}`);
        return `${accumulator}, ${values.join(', ')}`;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.tnc) {
      if (filter?.value.min) {
        nextValue += `, Min TNC: ${filter?.value.min}`;
        return nextValue;
      }
      return accumulator;
    }
    if (filter?.name === filterNames.addedDateMin) {
      if (filter?.value.length > 0) {
        return `${accumulator}, Added after: ${formatDate(filter?.value)}`;
      }
      return accumulator;
    }
    if (filter?.name) {
      log.error(`[filterToStringsHelper] Missing string conversion for '${filter?.name}' filter`);
    }
    return accumulator;
  };
  return appliedFilters.reduce(reducer, '').substring(2);
};

// eslint-disable-next-line no-restricted-exports
export { getAppliedFiltersAsString as default };
