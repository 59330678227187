const apiAlgorithms = {
  NovaControl: 'NovaControl',
  Atlas: 'Atlas',
  Solar: 'Solar',
};

export const algorithmNames = {
  ANSearchVersion1: 'AN Search Version 1',
  ANSearchVersion2: 'AN Search Version 2',
  Solar: 'Solar',
};

export const convertToAlgorithmName = (algorithm: string) => {
  switch (algorithm) {
    case apiAlgorithms.NovaControl:
      return algorithmNames.ANSearchVersion1;
    case apiAlgorithms.Atlas:
      return algorithmNames.ANSearchVersion2;
    case apiAlgorithms.Solar:
      return algorithmNames.Solar;
    default:
      return algorithm;
  }
};
