import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import * as searchActions from '../patientSearch/redux/actions';
import PatientDetailsForm from './forms/PatientDetailsForm/PatientDetailsForm';
import Selectors from '../core/redux/selectors';
import { LoadingMessage } from '../../core/index';
import * as actions from './redux/actions';

import type { PatientState } from '../types';
import type { RouterMatch } from '../../core/types';
import type { ReduxState } from '../../rootReducer';

type OwnProps = {
  match: RouterMatch;
};
type StateProps = {
  isPatientBeingSaved: boolean;
  patient?: PatientState;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  isPatientBeingSaved: Selectors.isPatientBeingSaved(state),

  patient: Selectors.getPatient(state, ownProps.match.params.patientId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getPatientDetails: bindActionCreators(searchActions.getPatientDetails, dispatch),
  savePatient: bindActionCreators(actions.saveUpdatedPatientDetails, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class PatientUpdate extends PureComponent<Props> {
  static defaultProps = {
    patient: undefined,
  };

  componentDidMount() {
    const { getPatientDetails, match } = this.props;
    const { patientId } = match.params;
    if (patientId) {
      getPatientDetails(patientId);
    }
  }

  render() {
    const { isPatientBeingSaved, patient, savePatient } = this.props;
    return (
      <div className="col span_10_of_12 push_1">
        <h1 className="border-bottom-solid">Update Patient</h1>
        <LoadingMessage isLoading={isPatientBeingSaved || !patient}>
          {/* @ts-expect-error - redux-form onSubmit function not typescript comparable */}
          <PatientDetailsForm updating onSubmit={savePatient} patientInfo={patient && patient.patientInfo} />
        </LoadingMessage>
      </div>
    );
  }
}

export default connector(PatientUpdate);
