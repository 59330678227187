import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import Select, { SingleValue } from 'react-select';
import { Button } from '../../../core/components/Button';
import { useQuery } from '../../../core/hooks/useQuery';
import { OptionWithValue } from '../../../core/types';
import formatGrid from '../../../core/helpers/gridFormatter';
import { ExternalInvestigationActions, MessageDenialAction, RepeatUKSearch } from '../../types';
import { getMessageDenialStatusName } from '../../../core/constants/messageDenialStatuses';
import { TransplantCentreTabType } from '../../constants/TransplantCentreTabTypes';

type Props = {
  transplantCentreTabType: TransplantCentreTabType;
  tableDataRows: (ExternalInvestigationActions | null)[] | MessageDenialAction[] | (RepeatUKSearch | null)[];
  settings?: { type: boolean; grid: boolean };
  setFilteredTestRequestsTableData?: (tableDataRow: (ExternalInvestigationActions | null)[]) => void;
  setFilteredMessageDenialsTableData?: (tableDataRow: MessageDenialAction[]) => void;
  setFilteredRepeatSearchTableData?: (tableDataRow: (RepeatUKSearch | null)[]) => void;
  setExpandedRows?: (expandedRows: number[]) => void;
  setActionIds?: (actionIds: number[]) => void;
};

const TransplantCentreFilterToolBar = ({
  tableDataRows,
  setFilteredTestRequestsTableData,
  setFilteredMessageDenialsTableData,
  setFilteredRepeatSearchTableData,
  setExpandedRows,
  setActionIds,
  settings,
  transplantCentreTabType,
}: Props) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  let messageDenialActions: MessageDenialAction[] = [];
  let repeatSearches: (RepeatUKSearch | null)[] = [];
  if (transplantCentreTabType === TransplantCentreTabType.MessageDenials) {
    messageDenialActions = tableDataRows as MessageDenialAction[];
  } else if (transplantCentreTabType === TransplantCentreTabType.RepeatUKSearches) {
    repeatSearches = tableDataRows as (RepeatUKSearch | null)[];
  }

  const transplantCentreOptions: OptionWithValue[] = _.uniqBy(
    tableDataRows.map((tableDataRow) => ({
      label: tableDataRow ? tableDataRow.transplantCentreName : '',
      value: tableDataRow ? tableDataRow.transplantCentreName : '',
    })),
    (tableDataRow) => tableDataRow.label
  );
  const orderedTransplantCentreOptions = _.orderBy(transplantCentreOptions, 'label', 'asc');

  const messageDenialActionTypes: OptionWithValue[] = _.uniqBy(
    messageDenialActions.map((messageDenialAction) => ({
      label: getMessageDenialStatusName(messageDenialAction.requestType) ?? messageDenialAction.requestType,
      value: messageDenialAction.requestType,
    })),
    (messageDenialAction) => messageDenialAction.label
  );
  const orderedMessageDenialActionTypes = _.orderBy(messageDenialActionTypes, 'label', 'asc');

  const uncheckActionIds = () => {
    if (setActionIds) {
      setActionIds([]);
    }
  };
  const [transplantCentreFilter, setTransplantCentreFilter] = useState('');
  const handleTransplantCentreFilterChange = (option: SingleValue<OptionWithValue>) => {
    const value = option ? option.value : '';
    setTransplantCentreFilter(value);
    uncheckActionIds();
  };

  const [patientIdFilter, setPatientIdFilter] = useState('');

  const handlePatientIdFilterChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setPatientIdFilter(e.currentTarget.value);
    uncheckActionIds();
  };

  const [gridFilter, setGridFilter] = useState('');

  const handleGridFilterChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setGridFilter(e.currentTarget.value);
    uncheckActionIds();
  };

  const [messageDenialActionTypeFilter, setMessageDenialActionTypeFilter] = useState('');

  const handleMessageDenialActionTypeFilterChange = (option: SingleValue<OptionWithValue>) => {
    const value = option ? option.value : '';
    setMessageDenialActionTypeFilter(value);
    uncheckActionIds();
  };

  const [dateSearchValue, setDateSearchValue] = useState('');
  const [dateSearchValueView, setDateSearchValueView] = useState('');

  const debouncedChangeHandler = useMemo(
    () =>
      _.debounce((value) => {
        setDateSearchValue(value);
      }, 200),
    [dateSearchValue]
  );

  const handleDateSearchValueChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const dateNowInMs = Date.now();
    const selectedDateInMs = new Date(value).getTime();

    if (dateNowInMs >= selectedDateInMs && selectedDateInMs > 0) {
      setDateSearchValueView(value);
      debouncedChangeHandler(value);
      if (setExpandedRows) {
        setExpandedRows([]);
      }
      uncheckActionIds();
    }
    if (!value || selectedDateInMs < 0) {
      setDateSearchValueView(value);
    }
  };

  const getFilteredTableRows = () => {
    if (transplantCentreTabType === TransplantCentreTabType.MessageDenials) {
      return !patientIdFilter &&
        !gridFilter &&
        !transplantCentreFilter &&
        !messageDenialActionTypeFilter &&
        !dateSearchValue
        ? tableDataRows
        : messageDenialActions.filter(
            (messageDenialAction) =>
              messageDenialAction?.transplantCentreName.includes(transplantCentreFilter) &&
              messageDenialAction?.patientId.includes(patientIdFilter) &&
              messageDenialAction?.requestType.toLowerCase().includes(messageDenialActionTypeFilter.toLowerCase()) &&
              (messageDenialAction?.grid?.toLowerCase().includes(gridFilter.toLowerCase()) ||
                formatGrid(messageDenialAction?.grid).toLowerCase().includes(gridFilter.toLowerCase()))
          );
    }
    if (transplantCentreTabType === TransplantCentreTabType.RepeatUKSearches) {
      return !patientIdFilter && !transplantCentreFilter && !dateSearchValue
        ? tableDataRows
        : repeatSearches.filter(
            (repeatSearch) =>
              repeatSearch?.transplantCentreName.includes(transplantCentreFilter) &&
              repeatSearch.formattedPatientId.includes(patientIdFilter)
          );
    }
    const testRequests = tableDataRows as ExternalInvestigationActions[];
    return !patientIdFilter && !gridFilter && !transplantCentreFilter && !dateSearchValue
      ? tableDataRows
      : testRequests.filter((testRequest) => {
          if (testRequest?.grid) {
            return (
              testRequest?.transplantCentreName.includes(transplantCentreFilter) &&
              testRequest.formattedPatientId.includes(patientIdFilter) &&
              (testRequest.grid.toLowerCase().includes(gridFilter.toLowerCase()) ||
                formatGrid(testRequest.grid).toLowerCase().includes(gridFilter.toLowerCase()))
            );
          }
          return testRequest;
        });
  };

  const onClearFilters = () => {
    uncheckActionIds();
    setTransplantCentreFilter('');
    setPatientIdFilter('');
    setGridFilter('');
    setMessageDenialActionTypeFilter('');
    setDateSearchValue('');
    // An invalid date value needs to be passed to clear the input
    setDateSearchValueView('no date');
    history.push({ search: '' });
  };

  useEffect(() => {
    const newQuery = new URLSearchParams();
    if (transplantCentreFilter) newQuery.set('tc', transplantCentreFilter);
    if (patientIdFilter) newQuery.set('patientId', patientIdFilter);
    if (gridFilter) newQuery.set('grid', gridFilter);
    if (messageDenialActionTypeFilter) newQuery.set('actionType', messageDenialActionTypeFilter);
    if (dateSearchValue) newQuery.set('date', dateSearchValue);
    history.push({ search: newQuery.toString() });

    if (tableDataRows) {
      if (setFilteredTestRequestsTableData) {
        setFilteredTestRequestsTableData(getFilteredTableRows() as ExternalInvestigationActions[]);
      }
      if (setFilteredMessageDenialsTableData) {
        setFilteredMessageDenialsTableData(getFilteredTableRows() as MessageDenialAction[]);
      }
      if (setFilteredRepeatSearchTableData) {
        setFilteredRepeatSearchTableData(getFilteredTableRows() as RepeatUKSearch[]);
      }
    }
  }, [
    transplantCentreFilter,
    patientIdFilter,
    gridFilter,
    dateSearchValue,
    messageDenialActionTypeFilter,
    history,
    tableDataRows,
  ]);

  useEffect(() => {
    history.push({ search: location.search });
    setTransplantCentreFilter(query.get('tc') || '');
    setPatientIdFilter(query.get('patientId') || '');
    setGridFilter(query.get('grid') || '');
    setMessageDenialActionTypeFilter(query.get('actionType') || '');
    setDateSearchValue(query.get('date') || '');
    setDateSearchValueView(query.get('date') || '');
  }, []);

  return (
    <div className="test-request-filters">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="transplantCentre">
        Transplant Centre
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable
          id="transplantCentre"
          name="transplantCentre"
          onChange={handleTransplantCentreFilterChange}
          options={orderedTransplantCentreOptions}
          value={transplantCentreFilter ? { label: transplantCentreFilter, value: transplantCentreFilter } : null}
        />
      </label>
      <label htmlFor="patientId" style={{ maxWidth: '10vw' }}>
        Patient ID
        <input id="patientId" type="text" value={patientIdFilter} onChange={handlePatientIdFilterChange} />
      </label>
      {settings && settings.grid && (
        <label htmlFor="grid">
          GRID
          <input id="grid" type="text" value={gridFilter} onChange={handleGridFilterChange} />
        </label>
      )}
      {settings && settings.type && (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
        <label htmlFor="messageDenialActionType" style={{ width: '15vw' }}>
          Type
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            isClearable
            id="messageDenialActionType"
            name="messageDenialActionType"
            onChange={handleMessageDenialActionTypeFilterChange}
            options={orderedMessageDenialActionTypes}
            value={
              messageDenialActionTypeFilter
                ? {
                    label: getMessageDenialStatusName(messageDenialActionTypeFilter) ?? messageDenialActionTypeFilter,
                    value: messageDenialActionTypeFilter,
                  }
                : null
            }
          />
        </label>
      )}
      <label htmlFor="date" style={{ maxWidth: '350px' }}>
        View all actioned events for specific date
        <input
          id="date"
          type="date"
          value={dateSearchValueView}
          onChange={handleDateSearchValueChange}
          max={moment().format('YYYY-MM-DD')}
        />
      </label>
      <Button buttonClass="btn btn--inline btn--secondary" text="Clear Filters" onClick={onClearFilters} />
    </div>
  );
};

TransplantCentreFilterToolBar.defaultProps = {
  isMessageDenials: false,
  isRepeatSearches: false,
  setFilteredTestRequestsTableData: undefined,
  setFilteredMessageDenialsTableData: undefined,
  setFilteredRepeatSearchTableData: undefined,
  setExpandedRows: undefined,
  setActionIds: undefined,
  settings: { type: true, grid: true },
};

export default TransplantCentreFilterToolBar;
