import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import Select, { MultiValue } from 'react-select';
import _ from 'lodash';

import * as patientCreationActions from '../../../../patientCreation/redux/actions';
import * as actions from '../../../redux/actions';
import Selectors from '../../../redux/selectors';

import { searchResultStatuses as searchStatuses } from '../../../constants/searchResultStatuses';
import type { EmdisSearch, Institution, PatientInfo, Registry } from '../../../../types';
import type { EmdisSearchCreationDetails, InternationalSearchCreationConstants } from '../../../types';
import { RegistryDetails } from './RegistryDetails';

import { SearchTypeDetails, searchTypesDetails } from '../../../../../core/constants/searchTypes';
import type { ApiExternalRegistryDetails, ApiSearchRequest } from '../../../../../donorMatchSearchRequests/types/api';
import { PatientSelectors } from '../../../../index';
import type { ReduxState } from '../../../../../rootReducer';
import { Button } from '../../../../../core/components/Button';

type OwnProps = {
  onSubmit: () => void;
  patientId: string;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
type StateProps = {
  creationConstants?: InternationalSearchCreationConstants;
  externalRegistries: Institution[];
  isCreatingRequest: boolean;
  patientDetails: PatientInfo;
  patientStatus: string;
  searchRequests: ApiSearchRequest[];
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  requestCreationDetails: EmdisSearchCreationDetails[];
  warning?: string;
  error?: string;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  creationConstants: Selectors.getInternationalSearchRequestCreationConstants(state),
  isCreatingRequest: Selectors.getEmdisIsCreating(state),
  externalRegistries: Selectors.getExternalRegistries(state),
  patientDetails: Selectors.getPatientDetails(state, ownProps.patientId),
  patientStatus: Selectors.getPatientStatus(state, ownProps.patientId),

  searchRequests: PatientSelectors.getPatientSearchRequest(state, ownProps.patientId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  createEmdisSearchRequest: bindActionCreators(actions.createEmdisSearchRequest, dispatch),
  fetchInternationalSearchCreationConstants: bindActionCreators(
    actions.fetchInternationalRequestCreationConstants,
    dispatch
  ),
  getExternalRegistries: bindActionCreators(patientCreationActions.getExternalRegistries, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const getRegistryOptions = (registries: Institution[]): Registry[] =>
  registries.map((registry: Institution) => ({
    value: parseInt(registry.Id, 10),
    label: registry.Name,
  }));

const getRegistriesWithExistingSearches = (searchRequests: ApiSearchRequest[]): ApiExternalRegistryDetails[] =>
  _.flatten(searchRequests.filter((r) => !!r.ExternalRegistryDetails).map((r) => r.ExternalRegistryDetails));

const genderExists = (gender: string) => !(!gender || gender === 'Unknown');

export class NewEmdisSearchRequestForm extends React.Component<Props, State> {
  static defaultProps = {
    creationConstants: undefined,
  };

  constructor(props: Props) {
    super(props);
    const { patientDetails } = this.props;
    const { diagnosis, gender } = patientDetails;
    let errorMessage;
    if (!diagnosis) {
      errorMessage = 'Patient is missing valid diagnosis information';
    } else if (!genderExists(gender)) {
      errorMessage = 'Patient is missing gender information';
    }

    this.state = {
      requestCreationDetails: [],
      error: errorMessage,
    };
  }

  componentDidMount() {
    const { fetchInternationalSearchCreationConstants, getExternalRegistries } = this.props;
    getExternalRegistries();
    fetchInternationalSearchCreationConstants();
  }

  render() {
    const { creationConstants, externalRegistries, isCreatingRequest, patientDetails, patientStatus, onClose } =
      this.props;
    const { gender } = patientDetails;
    const { error, requestCreationDetails, warning } = this.state;

    const orderedDetails = _.reverse(_.clone(requestCreationDetails));
    const registryOptions = getRegistryOptions(externalRegistries);

    const btnDisabled = !this.isSubmitEnabled() ? 'btn--disabled' : '';
    return (
      <form onSubmit={this.submitSearchRequestForm} className="popUpForm">
        <div className="newSearchRequestPopUp">
          <div>
            <h2 className="border-bottom-solid">Create New EMDIS Search Request</h2>
          </div>
          {patientStatus === 'Closed' && (
            <div className="warning-message">
              <span>This patient&apos;s record has been closed</span>
            </div>
          )}
          {genderExists(gender) && (
            <>
              <Select
                isMulti
                value={orderedDetails.map((d) => registryOptions.find((r: Registry) => r.value === d.registry.Id))}
                isClearable={false}
                options={registryOptions}
                // @ts-expect-error Select inserts 'undefined' into the typing of the arguments
                onChange={this.handleSelectedRegistriesChange}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select external registries.."
              />
              {orderedDetails.map((r) => (
                <RegistryDetails
                  key={r.registry.Id}
                  creationConstants={creationConstants}
                  requestCreationDetails={r}
                  updateRequestCreationDetails={(d: EmdisSearchCreationDetails) =>
                    this.handleRequestCreationDetailsChangeForRegistry(r.registry.Id, d)
                  }
                />
              ))}
              <div className="btn-actions">
                <button
                  type="submit"
                  className={`btn btn--primary btn--inline ${btnDisabled}`}
                  disabled={!this.isSubmitEnabled()}
                >
                  {isCreatingRequest ? 'Creating...' : 'Create Emdis Search'}
                </button>
                <Button buttonClass="btn btn--inline btn--secondary" text="Close" onClick={onClose} />
              </div>
            </>
          )}

          {error && (
            <div className="error-message" data-testid="error">
              <span>{error}</span>
            </div>
          )}
          {warning && !error && (
            <div className="warning-message">
              <span>{warning}</span>
            </div>
          )}
        </div>
      </form>
    );
  }

  handleSelectedRegistriesChange = (registryOptions: MultiValue<Registry>) => {
    const { externalRegistries } = this.props;
    const { requestCreationDetails } = this.state;
    const newRegistryIds = registryOptions
      .map((o) => o.value)
      .filter((id) => !requestCreationDetails.map((r) => r.registry.Id).includes(id));

    const newRequestCreationDetails = [
      ...requestCreationDetails.filter((d) => registryOptions.map((o) => o?.value).includes(d.registry.Id)),
      ...newRegistryIds.map((id) => ({
        searchTypes: [searchTypesDetails.POTENTIAL_SEARCH],
        registry: externalRegistries.find((r) => parseInt(r.Id, 10) === id),
      })),
    ] as EmdisSearchCreationDetails[];
    this.updateRequestCreationDetails(newRequestCreationDetails);
  };

  handleRequestCreationDetailsChangeForRegistry(registryId: number, details: EmdisSearchCreationDetails) {
    const { requestCreationDetails } = this.state;
    const newRequestCreationDetails = [...requestCreationDetails.filter((d) => d.registry.Id !== registryId), details];
    this.updateRequestCreationDetails(newRequestCreationDetails);
  }

  updateRequestCreationDetails(requestCreationDetails: EmdisSearchCreationDetails[]) {
    const { searchRequests } = this.props;

    const registriesWithExistingSearches = getRegistriesWithExistingSearches(searchRequests);
    const registriesWithOpenSearches = registriesWithExistingSearches.filter(
      (rd) => rd.Status === searchStatuses.initiated.value || rd.Status === searchStatuses.resultsAvailable.value
    );

    const selectedRegistriesWithOpenSearches = requestCreationDetails.filter((d) =>
      registriesWithOpenSearches.map((er) => er.RegistryId).includes(d.registry.Id.toString())
    );

    const warning = selectedRegistriesWithOpenSearches.some((x) => x)
      ? `The following registries already have open EMDIS searches. Are you sure you want to close the current open searches: ${selectedRegistriesWithOpenSearches
          .map((r) => r.registry.Name)
          .join(', ')}?`
      : undefined;

    this.setState({
      requestCreationDetails,
      warning,
    });
  }

  submitSearchRequestForm = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { createEmdisSearchRequest, onSubmit, patientId } = this.props;
    const { requestCreationDetails } = this.state;

    const emdisSearches = requestCreationDetails
      .map(
        (d) =>
          ({
            searchTypes: d.searchTypes.map((st: SearchTypeDetails) => st.value),
            registryId: d.registry.Id,
            abdrMaxDonors: d.abdrMaxDonors,
            cordMaxDonors: d.cordMaxDonors,
          } as EmdisSearch)
      )
      // $FlowExpectedError - https://github.com/facebook/flow/issues/7397
      .flat();
    const message = await createEmdisSearchRequest({
      patientId,
      emdisSearches,
    });

    if (!message) {
      onSubmit();
      return;
    }
    this.setState({ error: message as unknown as string });
  };

  isSubmitEnabled = () => {
    const { isCreatingRequest } = this.props;
    const { error, requestCreationDetails } = this.state;
    return (
      requestCreationDetails.some((x) => x) &&
      !requestCreationDetails.some((x) => x.searchTypes?.length === 0) &&
      !error &&
      !isCreatingRequest
    );
  };
}

export default connector(NewEmdisSearchRequestForm);
