import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Response } from '@an/nova-frontend-rest-client';
import { bindActionCreators } from 'redux';
import { formatDate } from '../../../core/helpers/dateHelpers';
import TextWithLimitedLines from '../../../core/components/TextWithLimitedLines/TextWithLimitedLines';
import { ActionType } from '../../redux/actions';
import { RepeatUKSearch, RepeatUKSearchEvent } from '../../types';
import { convertEventTypeToReadable } from '../../constants/eventTypes';
import { donorTypes, reportTypes, PopUpWrapper } from '../../../core';
import ActionsDialogue from '../Common/ActionsDialogue';
import Selectors from '../../redux/selectors';
import { ApiFailedActions, ApiSuceededActions, ApiUpdatedActionsResponse } from '../../types/api';
import { styles } from '../../../patient/patientDashboard/style';
import * as actions from '../../redux/actions';
import { orderRepeatUKSearchEvents } from '../../helpers/orderTestResults';

type Props = {
  events: RepeatUKSearchEvent[];
  searchId: string;
  repeatSearches: (RepeatUKSearch | null)[];
  setTableDataRows: (searches: (RepeatUKSearch | null)[]) => void;
};

const popUpStyle = {
  ...styles.patientDashboardPopUp,
  padding: '50px',
  border: '1px solid #dee2e6',
  fontSize: '14px',
  maxWidth: '35%',
  maxHeight: '50%',
  overflow: 'auto',
  width: '90%',
};

const RepeatUKSearchEventDetailsTable = ({ events, searchId, repeatSearches, setTableDataRows }: Props) => {
  const dispatch = useDispatch();
  const updateRepeatUKSearchActions = bindActionCreators(actions.updateRepeatUKSearchActions, dispatch);
  const isUpdating = useSelector(Selectors.isUpdating);
  const [eventRows, setEventRows] = useState(orderRepeatUKSearchEvents(events));
  const [actionIds, setActionIds] = useState<number[]>([]);
  const [isPopupShown, setIsPopupShown] = useState<boolean>(false);
  const [dialogueButtonText, setDialogueButtonText] = useState('Continue');
  const [failedActions, setFailedActions] = useState<ApiFailedActions | null>();
  const [succeededActions, setSucceededActions] = useState<ApiSuceededActions | null>();

  const isDisabled = actionIds.length === 0;

  const addOrRemove = (actionId: number) => {
    const newActionIds = [...actionIds];
    const index = newActionIds.indexOf(actionId);
    if (index === -1) {
      newActionIds.push(actionId);
    } else {
      newActionIds.splice(index, 1);
    }
    setActionIds(newActionIds);
  };

  const handleUpdateActions = async () => {
    // @ts-expect-error: Not recognising async action
    const response: Response<ApiUpdatedActionsResponse> = await updateRepeatUKSearchActions(
      actionIds,
      ActionType.RepeatUKSearch
    );
    const { body } = response.response;
    if (response.response.ok && body) {
      const succeededActionIds = body.SucceededActions.map((action) => action.ActionId);
      if (body.FailedActions.length > 0) {
        setEventRows(eventRows.filter((event) => !succeededActionIds.includes(event.actionId)));
        setDialogueButtonText('Retry');
        setFailedActions(body.FailedActions);
        setSucceededActions(body.SucceededActions);
        setActionIds(actionIds.filter((actionId) => !succeededActionIds.includes(actionId)));
      } else {
        setEventRows(eventRows.filter((event) => !succeededActionIds.includes(event.actionId)));
        setIsPopupShown(false);
        setDialogueButtonText('Continue');
        setActionIds([]);
      }
    }
  };

  const handleOpen = () => {
    setIsPopupShown(true);
  };

  const handleClose = () => {
    setIsPopupShown(false);
    setFailedActions(null);
    setDialogueButtonText('Continue');
  };

  useEffect(() => {
    const tableDataRows = repeatSearches.map((search) => {
      if (search) {
        if (search.firstRepeatSearchRequestId === searchId && eventRows.length === 0) {
          return null;
        }
        if (search.firstRepeatSearchRequestId === searchId) {
          return { ...search, actions: eventRows };
        }
      }
      return search;
    });
    setTableDataRows(tableDataRows);
  }, [eventRows]);

  return (
    <>
      <table className="table" style={{ margin: '10px 0px 10px' }}>
        <thead className="table-header">
          <tr>
            <th>Event Type</th>
            <th>Event Date</th>
            <th>Event Details</th>
            <th>Actioned</th>
            <th>View Results</th>
          </tr>
        </thead>
        <tbody>
          {eventRows.map((event) => (
            <tr key={`${searchId}-${event.actionId}`} className="centered-table-data event-row">
              <td>{convertEventTypeToReadable(event.eventType)}</td>
              <td>{formatDate(event.eventDate)}</td>
              <td>
                <TextWithLimitedLines tooltip={event.eventDetails} numberOfLines={2} lineHeightPx={20}>
                  {event.eventDetails}
                </TextWithLimitedLines>
              </td>
              <td>
                <input
                  className="form-checkbox"
                  checked={event.isActioned ? true : undefined}
                  disabled={event.isActioned}
                  type="checkbox"
                  id="checkbox"
                  onClick={() => addOrRemove(event.actionId)}
                />
              </td>
              <td className="table-button-cell">
                {event.areDonorsAdded && event.resultSetId && (
                  <Link
                    target="_blank"
                    to={`/donorsearch/${searchId}/results?resultSetId=${event.resultSetId}&donorType=${
                      donorTypes.adult.value
                    }&reportType=${reportTypes.internal}&donorsAddedAfter=${formatDate(event.eventDate, 'YYYY-MM-DD')}`}
                  >
                    View Added Donors
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <div style={{ textAlign: 'center' }}>
          <PopUpWrapper
            placement=""
            name="Update"
            shouldDarkenBackground
            buttonClassName="btn btn--inline"
            popoverContainerClassName="react-tiny-popover-container--center"
            onClick={handleOpen}
            onClosePopover={handleClose}
            isPopUpShown={isPopupShown}
            popUpStyle={popUpStyle}
            isButtonDisabled={isDisabled}
          >
            <ActionsDialogue
              onConfirm={handleUpdateActions}
              onClose={handleClose}
              isUpdating={isUpdating}
              dialogueButtonText={dialogueButtonText}
              failedActions={failedActions}
              succeededActions={succeededActions}
            />
          </PopUpWrapper>
        </div>
      </div>
    </>
  );
};

export default RepeatUKSearchEventDetailsTable;
