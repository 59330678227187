import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import donorTypes from '../../../core/constants/donorTypes';
import algorithmTypes from '../../../core/constants/algorithmTypes';
import InternationalDonorsTable from './ReportsTables/InternationalDonorsTable';
import { PatientSelectors } from '../../../patient';
import { LoadingMessage } from '../../../core';
import type { ReduxState } from '../../../rootReducer';
import type { ResultTableRow } from '../../../donorMatchSearchRequests/types';
import type { ApiSearchRequest } from '../../../donorMatchSearchRequests/types/api';
import type { DonorType } from '../../../core/types';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  patientId?: string;
  // used in `mapStateToProps`
  donorType: DonorType;
  reportType: string;
};
type StateProps = {
  isFetchingRequests: boolean;
  patientSearchRequests?: ApiSearchRequest[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

const mapStateToProps = (state: ReduxState, { patientId }: OwnProps): StateProps => ({
  patientSearchRequests: PatientSelectors.getPatientSearchRequest(state, patientId),
  isFetchingRequests: state.latestSearchRequests.searchRequests.isFetching,
});

const compareRequestedDate = (requestA: ApiSearchRequest, requestB: ApiSearchRequest) =>
  moment(requestB.RequestedDate).diff(moment(requestA.RequestedDate), 'seconds', true);

class InternationalRequestsList extends PureComponent<Props> {
  static defaultProps = {
    patientId: undefined,
    patientSearchRequests: undefined,
  };

  render() {
    const { isFetchingRequests } = this.props;
    return <LoadingMessage isLoading={isFetchingRequests}>{this.renderSets()}</LoadingMessage>;
  }

  renderSets() {
    const { donorType, patientId, patientSearchRequests, reportType } = this.props;
    const sortedRequests = patientSearchRequests ? patientSearchRequests.sort(compareRequestedDate) : [];

    const mappedResultSets = sortedRequests.reduce(
      (requests: ResultTableRow[], search: ApiSearchRequest) => [
        ...requests,
        ...search.ResultSetSummaries.map((resultSet) => ({
          algorithm: resultSet.AlgorithmUsed,
          donorType: resultSet.DonorType,
          exactMatchCount: resultSet.ExactMatchCount as number,
          externalRegistries: search.ExternalRegistryDetails,
          isHlaAdjusted: search.IsHlaAdjusted || !search.SearchedHlaMatchCurrentPatientHla,
          mismatchCount: resultSet.MismatchCount,
          potentialMatchCount: resultSet.PotentialMatchCount as number,
          requestedDate: search.RequestedDate,
          requestId: search.Id,
          resultSetId: resultSet.SearchResultSetId,
          resultSetSummaries: search.ResultSetSummaries,
          returnedSolarDonorCount: search.ReturnedSolarDonorCount,
          savedCordSets: search.SavedCordSets,
          savedDonorSets: search.SavedDonorSets,
          type: search.AdultSearchType,
        })),
      ],
      []
    );

    const filteredResultSets = mappedResultSets.filter(
      (x) => x.algorithm === algorithmTypes.external && x.donorType === donorTypes.adult.value
    );

    return (
      <div>
        {filteredResultSets.some((x) => x) ? (
          <InternationalDonorsTable
            donorType={donorType}
            filteredResultSets={filteredResultSets}
            patientId={patientId}
            reportType={reportType}
          />
        ) : (
          <h3>No international saved sets found for this patient.</h3>
        )}
      </div>
    );
  }
}

const connector = connect(mapStateToProps);

export default connector(InternationalRequestsList);
