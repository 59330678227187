export const ApiEventTypes = {
  AutomaticDeactivation: 'AutomaticDeactivation',
  ResultsUpdate: 'ResultsUpdate',
  FirstRunFailure: 'FirstRunFailure',
} as const;

export const ReadableEventTypes = {
  AutomaticDeactivation: 'Automatic Deactivation',
  ResultsUpdate: 'Results Update',
  FirstRunFailure: 'First Run Failure',
} as const;

export type ApiEventType = typeof ApiEventTypes[keyof typeof ApiEventTypes];
export type ReadableEventType = typeof ReadableEventTypes[keyof typeof ReadableEventTypes];

export const convertEventTypeToReadable = (eventType: ApiEventType): ReadableEventType => {
  switch (eventType) {
    case ApiEventTypes.AutomaticDeactivation:
      return ReadableEventTypes.AutomaticDeactivation;
    case ApiEventTypes.ResultsUpdate:
      return ReadableEventTypes.ResultsUpdate;
    case ApiEventTypes.FirstRunFailure:
      return ReadableEventTypes.FirstRunFailure;
    default:
      return eventType;
  }
};
