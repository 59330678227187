import React from 'react';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { RouterMatch } from '../../core/types';
import { TabItem, tabItems } from '../constants/tabItems';
import TestRequestsTab from './TestRequests/TestRequestsTab';
import MessageDenialTab from './MessageDenial/MessageDenialTab';
import RepeatUKSearchesTab from './RepeatUKSearches/RepeatUKSearchesTab';
import { FeatureFlagsSelectors, Features } from '../../featureFlags';
import { ReduxState } from '../../rootReducer';

type OwnProps = {
  match: RouterMatch;
};

type StateProps = {
  featureFlags: Features;
};

type Props = PropsFromRedux & OwnProps & StateProps;

const TransplantCentreTabs = ({ match, featureFlags }: Props) => {
  const isRepeatUkSearchesTabEnabled = (features: Features): boolean => features?.tcManagementRepeatUkSearches ?? false;

  const repeatUkSearchesTabEnabled = isRepeatUkSearchesTabEnabled(featureFlags);

  const { activeTab } = match.params;
  const renderTab = (item: TabItem) => {
    if (!repeatUkSearchesTabEnabled && item.displayName === 'Repeat UK Searches') {
      return null;
    }

    return (
      <li key={item.id}>
        <Link className={activeTab === item.id ? 'active' : ''} to={`/tc-management/${item.id}`}>
          {item.displayName}
        </Link>{' '}
      </li>
    );
  };

  const tabs = Object.values(tabItems)
    .map((item: TabItem) => renderTab(item))
    .filter((item) => item != null);

  return (
    <div className="content group fixed-width">
      <main role="main" className="col span_12_of_12">
        <ul className="nav-tabs">{tabs}</ul>
        {activeTab === tabItems.testResults.id && <TestRequestsTab />}
        {activeTab === tabItems.rejectedMessages.id && <MessageDenialTab />}
        {repeatUkSearchesTabEnabled && activeTab === tabItems.repeatUKSearches.id && <RepeatUKSearchesTab />}
      </main>
    </div>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  featureFlags: FeatureFlagsSelectors.getCurrentFeatureFlags(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransplantCentreTabs);
