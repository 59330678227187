import _ from 'lodash';
import moment from 'moment';
import { Action, ExternalInvestigationActions, MessageDenialAction, RepeatUKSearchEvent } from '../types';

const minActionId = (actions: Action[]) => {
  const startDate = moment(actions[0].receivedDate);
  actions.reduce((actionDate1, action2) => {
    const actionDate2 = moment(action2.receivedDate);
    return actionDate1 < actionDate2 ? actionDate1 : actionDate2;
  }, startDate);
};

export const orderExternalInvestigationActions = (
  externalInvestigationActions: ExternalInvestigationActions[]
): ExternalInvestigationActions[] =>
  _.orderBy(
    externalInvestigationActions,
    [(externalInvestigationAction) => minActionId(externalInvestigationAction.actions)],
    ['asc']
  );

export const orderMessageDenialActions = (messageDenialActions: MessageDenialAction[]): MessageDenialAction[] =>
  _.orderBy(messageDenialActions, [(rejectedMessagesAction) => moment(rejectedMessagesAction.receivedDate)], ['asc']);

export const orderActions = (actions: Action[]): Action[] =>
  _.orderBy(actions, [(action) => moment(action.receivedDate)], ['asc']);

export const orderRepeatUKSearchEvents = (events: RepeatUKSearchEvent[]): RepeatUKSearchEvent[] =>
  _.orderBy(events, [(event) => moment(event.eventDate)], ['asc']);
