import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ErrorMessage } from '../../../core';
import Selectors from '../../redux/selectors';
import { getRepeatUKSearches, getRepeatUKSearchesByDate } from '../../redux/actions';
import RepeatUKSearchesTable from './RepeatUKSearchesTable';

const RepeatUKSearchesTab = () => {
  const isFetching = useSelector(Selectors.isFetchingRepeatUKSearches);
  const errorMessage = useSelector(Selectors.getRepeatUKSearchesErrorMessage);
  const repeatUKSearches = useSelector(Selectors.getRepeatUKSearches);

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dateQueryParam = queryParams.get('date');

  useEffect(() => {
    if (queryParams.has('date')) {
      dispatch(getRepeatUKSearchesByDate(dateQueryParam as string));
      return;
    }
    dispatch(getRepeatUKSearches());
  }, [dateQueryParam]);

  return errorMessage ? (
    <div style={{ marginTop: '10px' }}>
      <ErrorMessage errorMessages={[errorMessage]} />
    </div>
  ) : (
    <div className="results-table results-table--border">
      <RepeatUKSearchesTable repeatUKSearches={repeatUKSearches} isLoading={isFetching} />
    </div>
  );
};

export default RepeatUKSearchesTab;
