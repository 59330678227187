import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { ErrorMessage } from '../../core';
import donorTypes from '../../core/constants/donorTypes';
import type { DonorType, RouterMatch } from '../../core/types';
import { DetailedPatientDisplay } from '../../patient';
import type { ReduxState } from '../../rootReducer';
import * as actions from '../redux/actions';
import Selectors from '../redux/selectors';
import { styles } from '../style';
import MatchedDonors from './MatchedDonors';

type QueryParams = {
  setId?: string;
  donorType: DonorType;
  registryIds?: string;
  reportId?: string;
  resultSetId: string;
  donorsAddedAfter?: string;
};
type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  location: any;
  // used in `mapStateToProps`
  match: RouterMatch;
};
type StateProps = {
  errorMessages: (string | undefined)[];
  patientId?: string;
  queryParams: QueryParams;
  searchHasErrored: boolean;
};
type Props = PropsFromRedux &
  OwnProps &
  StateProps & {
    updateDateFilter: (date: string) => void;
  };

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  const queryParams: QueryParams = queryString.parse(ownProps.location.search);
  return {
    errorMessages: Selectors.getErrorMessages(state, queryParams.resultSetId),
    patientId: Selectors.getRequestPatientId(state),
    queryParams,
    searchHasErrored: Selectors.getHasErrored(state, queryParams.resultSetId),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getFullResultsPageData: bindActionCreators(actions.getFullResultsPageData, dispatch),
  updateDateFilter: bindActionCreators(actions.updateDateFilter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ResultsPage extends PureComponent<Props> {
  static defaultProps = {
    patientId: undefined,
  };

  componentDidMount() {
    const { getFullResultsPageData, match, queryParams, updateDateFilter } = this.props;
    const { donorType, resultSetId, setId } = queryParams;
    getFullResultsPageData(match.params.id, donorType, resultSetId, setId);
    if (queryParams.donorsAddedAfter) {
      updateDateFilter(queryParams.donorsAddedAfter as string);
    }
  }

  render() {
    const { errorMessages, queryParams } = this.props;
    const { donorType, registryIds, reportId, resultSetId } = queryParams;
    const { patientId, searchHasErrored } = this.props;

    return (
      <div className="col span_12_of_12" style={styles.flexToFillAllTheSpace}>
        {searchHasErrored ? (
          <div className="col span_8_of_12 push_4">
            <ErrorMessage errorMessages={errorMessages} />
          </div>
        ) : (
          <div className="col span_12_of_12" style={styles.flexToFillAllTheSpace}>
            <div className="col span_12_of_12">
              <DetailedPatientDisplay patientId={patientId} />
            </div>
            <div className="col span_12_of_12" style={styles.flexToFillAllTheSpace}>
              <MatchedDonors
                isCord={this.isCord()}
                patientId={patientId}
                registryIds={registryIds}
                reportId={reportId}
                resultSetId={resultSetId}
                donorType={donorType}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  isCord() {
    const { queryParams } = this.props;
    return queryParams.donorType === donorTypes.cord.value;
  }
}

export default connector(ResultsPage);
