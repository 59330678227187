import React, { CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Selectors from '../../redux/selectors';
import ValidationResultsRow from './ValidationResultsRow';
import type { PatientState } from '../../../patient/types';
import CoreSelectors from '../../../patient/core/redux/selectors';
import HlaPairDisplay from '../../../patient/core/components/HlaPairDisplay';
import type { ReduxState } from '../../../rootReducer';
import type { DonorType, SearchResults } from '../../../core/types';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  donorType: DonorType;
  // used in `mapStateToProps`
  // eslint-disable-next-line react/no-unused-prop-types
  patientId?: string;
  // used in `mapStateToProps`
  // eslint-disable-next-line react/no-unused-prop-types
  resultSetId: string; // used in `mapStateToProps`
};
type StateProps = {
  filteredSearchResults?: SearchResults;
  patient?: PatientState;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  filteredSearchResults: Selectors.getFilteredSearchResults(state, ownProps.resultSetId, ownProps.donorType),

  patient: CoreSelectors.getPatient(state, ownProps.patientId),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const stickyHeaderStyle: CSSProperties = {
  position: 'sticky',
  top: 0,
  background: '#ffffff',
  borderColor: '#000000',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
};

const ValidationResultsTable = ({ patient, filteredSearchResults }: Props) => (
  <table style={{ width: 'auto', marginBottom: '50px' }}>
    {patient && (
      <tr>
        <th style={stickyHeaderStyle}>
          <div>Donor Id</div>
          <div>Match Grade</div>
        </th>
        <th style={stickyHeaderStyle}>
          A
          <HlaPairDisplay locus1={patient.patientInfo.hlaA1} locus2={patient.patientInfo.hlaA2} />
        </th>
        <th style={stickyHeaderStyle}>
          B
          <HlaPairDisplay locus1={patient.patientInfo.hlaB1} locus2={patient.patientInfo.hlaB2} />
        </th>
        <th style={stickyHeaderStyle}>
          C
          <HlaPairDisplay locus1={patient.patientInfo.hlaC1} locus2={patient.patientInfo.hlaC2} />
        </th>
        <th style={stickyHeaderStyle}>
          DQB1
          <HlaPairDisplay locus1={patient.patientInfo.dqB11} locus2={patient.patientInfo.dqB12} />
        </th>
        <th style={stickyHeaderStyle}>
          DRB1
          <HlaPairDisplay locus1={patient.patientInfo.drB11} locus2={patient.patientInfo.drB12} />
        </th>
        <th style={stickyHeaderStyle}>
          DPB1
          <HlaPairDisplay locus1={patient.patientInfo.dpB11} locus2={patient.patientInfo.dpB12} />
        </th>
      </tr>
    )}

    {filteredSearchResults ? filteredSearchResults.results.map((r) => <ValidationResultsRow result={r} />) : null}
  </table>
);

ValidationResultsTable.defaultProps = {
  filteredSearchResults: undefined,
  patient: undefined,
  patientId: undefined,
};

export default connector(ValidationResultsTable);
