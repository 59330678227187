import type { Response } from '@an/nova-frontend-rest-client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PopUpWrapper } from '../../../core';
import { getExternalInvestigationActionMessageTypeName } from '../../../core/constants/externalInvestigationActionMessageTypes';
import { formatDate } from '../../../core/helpers/dateHelpers';
import { styles } from '../../../patient/patientDashboard/style';
import { orderActions } from '../../helpers/orderTestResults';
import Selectors from '../../redux/selectors';
import * as actions from '../../redux/actions';
import { Action, ExternalInvestigationActions } from '../../types';
import { ApiFailedActions, ApiSuceededActions, ApiUpdatedActionsResponse } from '../../types/api';
import ActionsDialogue from '../Common/ActionsDialogue';

type Props = {
  setTableDataRows: (tableDataRows: (ExternalInvestigationActions | null)[]) => void;
  externalInvestigationActions: Action[];
  testRequests: (ExternalInvestigationActions | null)[];
  externalInvestigationId: number;
};

const popUpStyle = {
  ...styles.patientDashboardPopUp,
  padding: '50px',
  border: '1px solid #dee2e6',
  fontSize: '14px',
  maxWidth: '35%',
  maxHeight: '50%',
  overflow: 'auto',
  width: '90%',
};

const TestRequestActionsTable = ({
  externalInvestigationActions,
  setTableDataRows,
  externalInvestigationId,
  testRequests,
}: Props) => {
  const dispatch = useDispatch();
  const updateExternalInvestigationActions = bindActionCreators(actions.updateExternalInvestigationActions, dispatch);
  const isUpdating = useSelector(Selectors.isUpdating);
  const orderedActions = orderActions(externalInvestigationActions);
  const [actionRows, setActionRows] = useState(orderedActions);
  const [actionIds, setActionIds] = useState<number[]>([]);
  const [isPopupShown, setIsPopupShown] = useState<boolean>(false);
  const [dialogueButtonText, setDialogueButtonText] = useState('Continue');
  const [failedActions, setFailedActions] = useState<ApiFailedActions | null>();
  const [succeededActions, setSucceededActions] = useState<ApiSuceededActions | null>();

  const isDisabled = actionIds.length === 0;

  const addOrRemove = (actionId: number) => {
    const newActionIds = [...actionIds];
    const index = newActionIds.indexOf(actionId);
    if (index === -1) {
      newActionIds.push(actionId);
    } else {
      newActionIds.splice(index, 1);
    }
    setActionIds(newActionIds);
  };

  const handleUpdateExternalInvestigationActions = async () => {
    // @ts-expect-error: Not recognising async action
    const response: Response<ApiUpdatedActionsResponse> = await updateExternalInvestigationActions(
      actionIds,
      actions.ActionType.TestRequest
    );
    const { body } = response.response;
    if (response.response.ok && body) {
      const succeededActionIds = body.SucceededActions.map((action) => action.ActionId);
      if (body.FailedActions.length > 0) {
        setActionRows(actionRows.filter((action) => !succeededActionIds.includes(action.actionId)));
        setDialogueButtonText('Retry');
        setFailedActions(body.FailedActions);
        setSucceededActions(body.SucceededActions);
        setActionIds(actionIds.filter((actionId) => !succeededActionIds.includes(actionId)));
      } else {
        setActionRows(actionRows.filter((action) => !succeededActionIds.includes(action.actionId)));
        setIsPopupShown(false);
        setDialogueButtonText('Continue');
        setActionIds([]);
      }
    }
  };

  const handleOpen = () => {
    setIsPopupShown(true);
  };

  const handleClose = () => {
    setIsPopupShown(false);
    setFailedActions(null);
    setDialogueButtonText('Continue');
  };

  useEffect(() => {
    const tableDataRows = testRequests.map((testRequest) => {
      if (testRequest) {
        if (testRequest.id === externalInvestigationId && actionRows.length === 0) {
          return null;
        }
        if (testRequest.id === externalInvestigationId) {
          return { ...testRequest, actions: actionRows };
        }
      }
      return testRequest;
    });
    setTableDataRows(tableDataRows);
  }, [actionRows]);

  return (
    <>
      <table className="table" style={{ margin: '10px 0px 10px' }}>
        <thead className="table-header">
          <tr>
            <th>Message Type</th>
            <th>Received Date</th>
            <th>Actioned</th>
          </tr>
        </thead>
        <tbody>
          {actionRows.map((action) => (
            <tr className="centered-table-data" key={action.actionId}>
              <td>{getExternalInvestigationActionMessageTypeName(action.messageType)}</td>
              <td>{formatDate(action.receivedDate)}</td>
              <td>
                <input
                  className="form-checkbox"
                  checked={action.isActioned ? true : undefined}
                  disabled={action.isActioned}
                  type="checkbox"
                  id="checkbox"
                  onClick={() => addOrRemove(action.actionId)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <div style={{ textAlign: 'center' }}>
          <PopUpWrapper
            placement=""
            name="Update"
            shouldDarkenBackground
            buttonClassName="btn btn--inline"
            popoverContainerClassName="react-tiny-popover-container--center"
            onClick={handleOpen}
            onClosePopover={handleClose}
            isPopUpShown={isPopupShown}
            popUpStyle={popUpStyle}
            isButtonDisabled={isDisabled}
          >
            <ActionsDialogue
              onConfirm={handleUpdateExternalInvestigationActions}
              onClose={handleClose}
              isUpdating={isUpdating}
              dialogueButtonText={dialogueButtonText}
              failedActions={failedActions}
              succeededActions={succeededActions}
            />
          </PopUpWrapper>
        </div>
      </div>
    </>
  );
};
export default TestRequestActionsTable;
