import type { Response } from '@an/nova-frontend-rest-client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingMessage, PopUpWrapper } from '../../../core';
import TextWithLimitedLines from '../../../core/components/TextWithLimitedLines/TextWithLimitedLines';
import { getMessageDenialStatusName } from '../../../core/constants/messageDenialStatuses';
import formatGrid from '../../../core/helpers/gridFormatter';
import { formatDate } from '../../../core/helpers/dateHelpers';
import { styles } from '../../../patient/patientDashboard/style';
import { orderMessageDenialActions } from '../../helpers/orderTestResults';
import * as actions from '../../redux/actions';
import Selectors from '../../redux/selectors';
import { MessageDenialAction } from '../../types';
import { ApiFailedActions, ApiSuceededActions, ApiUpdatedActionsResponse } from '../../types/api';
import ActionsDialogue from '../Common/ActionsDialogue';
import TransplantCentreFilterToolBar from '../Common/TransplantCentreFilterToolBar';
import './message-denial.scss';
import { TransplantCentreTabType } from '../../constants/TransplantCentreTabTypes';

const popUpStyle = {
  ...styles.patientDashboardPopUp,
  padding: '50px',
  border: '1px solid #dee2e6',
  fontSize: '14px',
  maxWidth: '35%',
  maxHeight: '50%',
  overflow: 'auto',
  width: '90%',
};

type Props = {
  messageDenialActions: MessageDenialAction[];
  isLoading: boolean;
};

export const MessageDenialTable = ({ messageDenialActions, isLoading }: Props) => {
  const dispatch = useDispatch();
  const updateMessageDenialsActions = bindActionCreators(actions.updateMessageDenialsActions, dispatch);
  const isUpdating = useSelector(Selectors.isUpdating);
  const tableData = orderMessageDenialActions(messageDenialActions);
  const [tableDataRows, setTableDataRows] = useState<MessageDenialAction[]>(tableData);
  const [filteredMessageDenialsTableData, setFilteredMessageDenialsTableData] =
    useState<MessageDenialAction[]>(tableData);

  useEffect(() => {
    setFilteredMessageDenialsTableData(tableData);
    setTableDataRows(tableData);
  }, [messageDenialActions]);

  const renderGrid = (grid: string | null | undefined) => {
    const formattedGrid = formatGrid(grid);
    return formattedGrid === '' ? '-' : formattedGrid;
  };

  const [actionIds, setActionIds] = useState<number[]>([]);

  const addOrRemove = (actionId: number) => {
    const newActionIds = [...actionIds];
    const index = newActionIds.indexOf(actionId);
    if (index === -1) {
      newActionIds.push(actionId);
    } else {
      newActionIds.splice(index, 1);
    }
    setActionIds(newActionIds);
  };

  const renderItem = (action: MessageDenialAction | null): JSX.Element | null =>
    action ? (
      <tr key={action.actionId} className="centered-table-data">
        <td> {action.transplantCentreName} </td>
        <td className="message-denial__patientId">{action.patientId}</td>
        <td>{renderGrid(action.grid)}</td>
        <td>{getMessageDenialStatusName(action.requestType)}</td>
        <td className="message-denial__receivedDate">{formatDate(action.receivedDate)}</td>
        <td className="message-denial__emdisRegistryCell">{action.emdisRegistryName}</td>
        <td>
          <TextWithLimitedLines tooltip={action.originOfDenial} numberOfLines={2} lineHeightPx={20}>
            {action.originOfDenial}
          </TextWithLimitedLines>
        </td>
        <td className="message-denial__remarkCell">
          <TextWithLimitedLines tooltip={action.remark} numberOfLines={2} lineHeightPx={20}>
            {action.remark}
          </TextWithLimitedLines>
        </td>
        <td>
          <input
            className="form-checkbox"
            checked={(action.isActioned ? true : undefined) || actionIds.includes(action.actionId)}
            disabled={action.isActioned}
            type="checkbox"
            id="checkbox"
            onClick={() => addOrRemove(action.actionId)}
          />
        </td>
      </tr>
    ) : null;

  const [isPopupShown, setIsPopupShown] = useState<boolean>(false);
  const [dialogueButtonText, setDialogueButtonText] = useState('Continue');
  const [failedActions, setFailedActions] = useState<ApiFailedActions | null>();
  const [succeededActions, setSucceededActions] = useState<ApiSuceededActions | null>();

  const handleUpdateMessageDenialsActions = async () => {
    // @ts-expect-error Type '(dispatch: Dispatch<any>) => Promise<any>' is not assignable to type 'Response<ApiUpdatedActionsResponse>'
    const response: Response<ApiUpdatedActionsResponse> = await updateMessageDenialsActions(
      actionIds,
      actions.ActionType.MessageDenial
    );
    const { body } = response.response;
    if (response.response.ok && body) {
      const succeededActionIds = body.SucceededActions.map((action) => action.ActionId);
      if (body.FailedActions.length > 0 && tableDataRows) {
        setTableDataRows(tableDataRows.filter((action) => !succeededActionIds.includes(action.actionId)));
        setDialogueButtonText('Retry');
        setFailedActions(body.FailedActions);
        setSucceededActions(body.SucceededActions);
        setActionIds(actionIds.filter((actionId) => !succeededActionIds.includes(actionId)));
      } else {
        setTableDataRows(tableDataRows.filter((action) => !succeededActionIds.includes(action.actionId)));
        setIsPopupShown(false);
        setDialogueButtonText('Continue');
        setActionIds([]);
      }
    }
  };

  const handleOpen = () => {
    setIsPopupShown(true);
  };

  const handleClose = () => {
    setIsPopupShown(false);
    setFailedActions(null);
    setDialogueButtonText('Continue');
  };

  const isDisabled = actionIds.length === 0;
  // TODO: EM-1767: Remove this when we will discus "Do we need unchecked all items after updating filter?"
  // const isDisabled = filteredMessageDenialsTableData.some(({ actionId }) => actionIds.includes(actionId));

  return (
    <>
      <TransplantCentreFilterToolBar
        tableDataRows={tableDataRows}
        setFilteredMessageDenialsTableData={setFilteredMessageDenialsTableData}
        setActionIds={setActionIds}
        transplantCentreTabType={TransplantCentreTabType.MessageDenials}
      />
      <LoadingMessage isLoading={isLoading}>
        <div className="table-wrapper" style={{ maxHeight: 'calc(54vh - 64px)' }}>
          <table className="table">
            <thead className="table-header table--sticky-header">
              <tr>
                <th>TC</th>
                <th className="message-denial__patientId">Patient ID</th>
                <th className="message-denial__gridCell">Grid</th>
                <th>Type</th>
                <th className="message-denial__receivedDate">Received Date</th>
                <th className="message-denial__emdisRegistryCell">EMDIS Registry</th>
                <th>Origin of Denial</th>
                <th className="message-denial__remarkCell">Remark</th>
                <th>Actioned</th>
              </tr>
            </thead>
            <tbody>
              {filteredMessageDenialsTableData?.length > 0 ? (
                filteredMessageDenialsTableData.map((messageDenialAction) => renderItem(messageDenialAction))
              ) : (
                <tr>
                  <td colSpan={9} style={{ textAlign: 'center', padding: '20px' }}>
                    No messages found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: 'center' }}>
          <PopUpWrapper
            placement=""
            name="Update"
            shouldDarkenBackground
            buttonClassName="btn btn--inline"
            popoverContainerClassName="react-tiny-popover-container--center"
            onClick={handleOpen}
            onClosePopover={handleClose}
            isPopUpShown={isPopupShown}
            popUpStyle={popUpStyle}
            isButtonDisabled={isDisabled}
          >
            <ActionsDialogue
              onConfirm={handleUpdateMessageDenialsActions}
              onClose={handleClose}
              isUpdating={isUpdating}
              dialogueButtonText={dialogueButtonText}
              failedActions={failedActions}
              succeededActions={succeededActions}
              isMessageDenials
            />
          </PopUpWrapper>
        </div>
      </LoadingMessage>
    </>
  );
};

export default MessageDenialTable;
