import { ReduxState } from '../../rootReducer';
import { ExternalInvestigationActions, RepeatUKSearch } from '../types';

const getTransplantCentreManagementInfo = (state: ReduxState) => state.transplantCentreManagement;

const getExternalInvestigationsActions = (state: ReduxState): ExternalInvestigationActions[] =>
  state.transplantCentreManagement.externalInvestigationActions.externalInvestigationsActions;
const isFetchingExternalInvestigationsActions = (state: ReduxState): boolean =>
  state.transplantCentreManagement.externalInvestigationActions.isFetching;
const hasFetchExternalInvestigationActionsErrored = (state: ReduxState): boolean =>
  getTransplantCentreManagementInfo(state).externalInvestigationActions.hasErrored;
const getExternalInvestigationActionsErrorMessage = (state: ReduxState): string[] => {
  const error = getTransplantCentreManagementInfo(state).externalInvestigationActions.errorMessage;
  return error ? [error] : [];
};
const isUpdatingExternalInvestigationActions = (state: ReduxState): boolean =>
  state.transplantCentreManagement.externalInvestigationActions.isUpdating;

const getMessageDenialActionsReducer = (state: ReduxState) => state.transplantCentreManagement.messageDenialActions;
const getMessageDenialActions = (state: ReduxState) => getMessageDenialActionsReducer(state).messageDenialActions;
const isFetchingMessageDenialActions = (state: ReduxState): boolean => getMessageDenialActionsReducer(state).isFetching;
const getMessageDenialActionsErrorMessage = (state: ReduxState): string | undefined =>
  getMessageDenialActionsReducer(state).errorMessage;

const getRepeatUKSearchesReducer = (state: ReduxState) => state.transplantCentreManagement.repeatUKSearches;
const getRepeatUKSearches = (state: ReduxState): RepeatUKSearch[] => getRepeatUKSearchesReducer(state).repeatUKSearches;
const isFetchingRepeatUKSearches = (state: ReduxState): boolean => getRepeatUKSearchesReducer(state).isFetching;
const getRepeatUKSearchesErrorMessage = (state: ReduxState): string | undefined =>
  getRepeatUKSearchesReducer(state).errorMessage;
const isUpdatingRepeatUKSearches = (state: ReduxState): boolean => getRepeatUKSearchesReducer(state).isUpdating;

export default {
  getExternalInvestigationsActions,
  isFetchingExternalInvestigationsActions,
  getExternalInvestigationActionsErrorMessage,
  hasFetchExternalInvestigationActionsErrored,
  isUpdating: isUpdatingExternalInvestigationActions,

  getMessageDenialActions,
  isFetchingMessageDenialActions,
  getMessageDenialActionsErrorMessage,

  getRepeatUKSearches,
  isFetchingRepeatUKSearches,
  getRepeatUKSearchesErrorMessage,
  isUpdatingRepeatUKSearches,
};
