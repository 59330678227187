import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { Moment } from 'moment';
import * as actions from '../redux/actions';
import * as patientActions from '../../../patient/patientDashboard/redux/actions';
import PatientSelectors from '../../../patient/patientDashboard/redux/selectors';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import { InternationalReportTypes, ReportTypes, ReportType as ReportTypeType } from '../../core/constants';
import type { ReduxState } from '../../../rootReducer';
import { SavedResultSet } from '../../../donorMatchSearchRequests/types';
import { SearchRequestSelectors } from '../../../donorMatchSearchRequests';

type DispatchProps = {
  fetchRepeatSearchDate: typeof patientActions.fetchRepeatSearchDate;
  updateReportType: typeof actions.updateReportType;
};
type OwnProps = {
  isInternationalReport: boolean;
  patientId?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  selectedDonorSets?: SavedResultSet[];
};
type StateProps = {
  isReportReadOnly: boolean;
  repeatSearchDate?: Moment;
  reportType?: string;
  areAllRepeatSearches: boolean;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  repeatSearchDate: PatientSelectors.getRepeatSearchDate(state, ownProps.patientId),
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
  reportType: CurrentReportSelectors.getReportType(state),
  areAllRepeatSearches:
    ownProps.selectedDonorSets && ownProps.selectedDonorSets.every((set) => set?.id)
      ? SearchRequestSelectors.areAllDonorSetsRepeatSearches(
          state,
          ownProps.selectedDonorSets.map((set) => set.id!)
        )
      : false,
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  fetchRepeatSearchDate: bindActionCreators(patientActions.fetchRepeatSearchDate, dispatch),
  updateReportType: bindActionCreators(actions.updateReportType, dispatch),
});

class ReportType extends PureComponent<Props> {
  static defaultProps = {
    patientId: undefined,
    repeatSearchDate: undefined,
    reportType: undefined,
    selectedDonorSets: undefined,
  };

  async componentDidMount() {
    const { fetchRepeatSearchDate, patientId, reportType, updateReportType, areAllRepeatSearches } = this.props;

    if (patientId) {
      const searchDate = await fetchRepeatSearchDate(patientId);
      if (areAllRepeatSearches && !reportType) {
        updateReportType(ReportTypes.AutomaticRepeatSearch.apiString);
      } else if (searchDate && !reportType) {
        // @ts-expect-error: Not recognising return type from dispatched actions
        const reportTypeToSelect = searchDate.payload
          ? InternationalReportTypes.ReSearch.apiString
          : InternationalReportTypes.InitialSearch.apiString;
        updateReportType(reportTypeToSelect);
      }
    }
  }

  render() {
    const { isInternationalReport } = this.props;
    const reportTypes = isInternationalReport ? InternationalReportTypes : ReportTypes;
    return (
      <div>
        <h2 className="border-bottom-solid">Search Type Title</h2>
        <div style={{ display: 'flex', paddingBottom: '20px' }}>
          {_.values(reportTypes).map(this.renderReportTypeCheckBox)}
        </div>
      </div>
    );
  }

  renderReportTypeCheckBox = (reportType: ReportTypeType) => {
    const { isReportReadOnly } = this.props;
    return (
      <label
        key={reportType.apiString}
        className="control control--radio"
        htmlFor={reportType.displayString}
        style={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          fontWeight: this.shouldBoldReportType(reportType.apiString) ? 'bold' : 'normal',
          marginRight: '20px',
        }}
      >
        <input
          checked={this.isReportTypeSelected(reportType.apiString)}
          disabled={isReportReadOnly}
          id={reportType.displayString}
          name="reportType"
          onChange={this.handleReportTypeClick}
          style={{ cursor: 'pointer', marginRight: '15px' }}
          type="radio"
          value={reportType.apiString}
        />
        {reportType.displayString}
      </label>
    );
  };

  handleReportTypeClick = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { reportType, updateReportType } = this.props;
    const newReportType = event.currentTarget.value;
    if (newReportType !== reportType) {
      updateReportType(newReportType);
    } else {
      updateReportType(undefined);
    }
  };

  isReportTypeSelected = (reportTypeSelected: string): boolean => {
    const { isInternationalReport, repeatSearchDate, reportType, areAllRepeatSearches } = this.props;

    const isSelectedReportType = reportType === reportTypeSelected;
    const isInitialSearchIfSearchDateNull =
      isInternationalReport &&
      !reportType &&
      reportTypeSelected === InternationalReportTypes.InitialSearch.apiString &&
      repeatSearchDate === null;
    const isReSearchIfSearchDateExists =
      isInternationalReport &&
      !reportType &&
      repeatSearchDate &&
      reportTypeSelected === InternationalReportTypes.ReSearch.apiString;
    const isAutomaticRepeatSearch =
      !isInternationalReport &&
      !reportType &&
      reportTypeSelected === ReportTypes.AutomaticRepeatSearch.apiString &&
      areAllRepeatSearches;

    return (
      isSelectedReportType || isInitialSearchIfSearchDateNull || isReSearchIfSearchDateExists || isAutomaticRepeatSearch
    );
  };

  shouldBoldReportType = (reportType: string) => {
    const { isReportReadOnly } = this.props;
    return isReportReadOnly && this.isReportTypeSelected(reportType);
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ReportType);
